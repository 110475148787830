@charset "UTF-8";

/************ TABLE OF CONTENTS ***************

  01. VARIBALES CSS START
  02. BASE CSS START
  03. TYPOGRAPHY CSS START
  04. BUTTON CSS START
  05. TITLE CSS START
  06. WIDGET CSS START
  07. ANIMATION CSS START
  08. SCROLL CSS START
  09. LOADING CSS START
  10. MODAL CSS START
  11. HEADER CSS START
  12. HERO CSS START
  13. OFFCANVAS CSS START
  14. BREADCRUMB CSS START
  15. SLIDER CSS START
  16. ABOUT CSS START
  17. SERVICE CSS START
  18. COUNTER CSS START
  19. CASE STUDY CSS START
  20. FAQ CSS START
  21. TESTIMONIAL CSS START
  22. TEAM CSS START
  23. PORTFOLIO CSS START
  24. COMPANY CSS START
  25. FEATURE CSS START
  26. PROGRESS CSS START
  27. WORKFLOW CSS START
  28. STEP CSS START
  29. GALLERY CSS START
  30. BLOG CSS START
  31. CONTACT CSS START
  32. 404 CSS START
  33. FOOTER CSS START
**********************************************/
/*----------------------------------------*/
/*  01. VARIBALES CSS START
/*----------------------------------------*/
:root {
  --primary: #e3a23a;
  --secondary: #171818;
  --orange: #ff8126;
  --orange-2: #ffcba5;
  --black: #000;
  --black-2: #666666;
  --black-3: #222222;
  --black-4: #999999;
  --white: #fff;
  --white-2: #f1f1f1;
  --white-3: #fff1e7;
  --white-4: #f4f4f4;
  --white-5: #f0d4c0;
  --white-6: #f9f9f9;
  --white-7: #efefef;
  --white-8: #f2f2f2;
  --gray: #e8e8e8;
  --gray-2: #262626;
  --gray-3: #a3a3a3;
  --gray-4: #6d6d6d;
  --gray-5: #d2d2d2;
  --gray-6: #fafafa;
  --gray-7: #8c8c8c;
}

/*----------------------------------------*/
/* 02. BASE CSS START
/*----------------------------------------*/
@font-face {
  font-family: EuclidCircularB;
  src: url("../fonts/Euclid Circular B Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: EuclidCircularB;
  src: url("../fonts/Euclid Circular B Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: EuclidCircularB;
  src: url("../fonts/Euclid Circular B Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: EuclidCircularB;
  src: url("../fonts/Euclid Circular B SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: EuclidCircularB;
  src: url("../fonts/Euclid Circular B Bold.ttf");
  font-weight: 700;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1170px;
  }
}

@media only screen and (min-width: 1920px) {
  .col-xxxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 17%;
  }

  .col-xxxl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxxl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58%;
  }
}

.zi-9 {
  position: relative;
  z-index: 9;
}

.b-left {
  border-left: 1px solid var(--white-7);
}

.b-right {
  border-right: 1px solid var(--white-7);
}

.br-left {
  border-radius: 5px 0 0 5px;
}

.br-right {
  border-radius: 0 5px 5px 0;
}

.menu-fixed {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background-color: var(--white);
  border-bottom: 1px solid var(--white-7);
  z-index: 99;
}

/*----------------------------------------*/
/* 03. TYPOGRAPHY CSS START
/*----------------------------------------*/
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: EuclidCircularB;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

p {
  padding: 0;
  margin: 0;
  font-style: normal;
  line-height: 1.5;
  font-weight: 400;
  font-size: 16px;
  color: var(--black-2);
}

/*----------------------------------------*/
/* 04. BUTTON CSS START
/*----------------------------------------*/
.wc-btn-primary {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: var(--white);
  background-color: var(--primary);
  padding: 18px 42px;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.wc-btn-primary span {
  position: absolute;
  width: 0px;
  height: 0px;
  background: var(--secondary);
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: -1;
}

.wc-btn-primary:hover {
  background-color: var(--secondary);
  color: var(--white);
}

.wc-btn-primary:hover span {
  width: 300px;
  height: 300px;
}

.wc-btn-secondary {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: var(--white);
  background-color: var(--secondary);
  padding: 18px 42px;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.wc-btn-secondary span {
  position: absolute;
  width: 0px;
  height: 0px;
  background: var(--primary);
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: -1;
}

.wc-btn-secondary:hover {
  background-color: var(--primary);
  color: var(--white);
}

.wc-btn-secondary:hover span {
  width: 300px;
  height: 300px;
}

/*----------------------------------------*/
/*  05. TITLE CSS START
/*----------------------------------------*/
.section-title {
  font-weight: 500;
  font-size: 25px;
  color: var(--secondary);
  line-height: 1.1;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .section-title {
    font-size: 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title {
    font-size: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title {
    font-size: 26px;
  }
}

.section-title span {
  color: var(--primary);
}

.section-sub-title {
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--black-2);
  padding: 6px 10px;
  background: var(--white-4);
  border-radius: 4px;
  position: relative;
  z-index: 9;
  margin-bottom: 15px;
}

.section-sub-title-2 {
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  color: var(--secondary);
  position: relative;
  text-transform: capitalize;
  margin-bottom: 20px;
  position: relative;
  z-index: 9;
}

.section-sub-title-2::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 1px;
  right: -60px;
  top: calc(50% + 1px);
  background-color: var(--secondary);
}

.about__left-2 .section-sub-title-2 {
  color: white !important;
}

.about__left-2 .section-title {
  color: white !important;
}

/*----------------------------------------*/
/*  06. WIDGET CSS START
/*----------------------------------------*/
.widget__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: var(--secondary);
  text-transform: capitalize;
  position: relative;
  padding: 0 0 30px 10px;
}

.widget__title::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 25px;
  left: 0;
  top: 0;
  background: var(--primary);
  border-radius: 1px;
}

.widget__title-2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: var(--secondary);
  text-transform: capitalize;
  padding-bottom: 20px;
}

.widget__service {
  background-color: #f5f5f5;
  padding: 30px;
  margin-bottom: 30px;
  margin-top: 10px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget__service {
    padding: 30px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .widget__service {
    padding: 20px 10px;
  }
}

.widget__service ul {
  background-color: var(--white);
}

.widget__service ul li a {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--secondary);
  padding: 17px 20px;
  border-bottom: 1px solid #f5f5f5;
  border-radius: 3px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget__service ul li a {
    font-size: 14px;
    grid-gap: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .widget__service ul li a {
    font-size: 14px;
    grid-gap: 10px;
  }
}

.widget__service ul li a:hover {
  background-color: var(--primary);
  color: var(--white);
}

.widget__project-details {
  background-color: #f5f5f5;
  padding: 30px;
  margin-bottom: 30px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget__project-details {
    padding: 30px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .widget__project-details {
    padding: 20px 10px;
  }
}

.widget__project-details ul li {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 0.1fr 20px 1.3fr;
  grid-template-columns: 1fr 0.1fr 1.3fr;
  grid-gap: 20px;
  font-size: 16px;
  line-height: 26px;
  color: var(--black-2);
  text-align: right;
  padding-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget__project-details ul li {
    font-size: 14px;
    grid-gap: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .widget__project-details ul li {
    font-size: 14px;
    grid-gap: 10px;
  }
}

.widget__project-details ul li strong,
.widget__project-details ul li span {
  font-weight: 500;
  color: var(--secondary);
  text-align: left;
}

.widget__contact {
  background-color: var(--secondary);
  padding: 50px 30px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget__contact {
    padding: 30px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .widget__contact {
    padding: 30px 15px;
  }
}

.widget__contact p {
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  color: var(--primary);
  padding-bottom: 10px;
}

.widget__contact h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: var(--white);
  padding-bottom: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget__contact h3 {
    font-size: 20px;
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .widget__contact h3 {
    font-size: 20px;
    padding-bottom: 20px;
  }
}

.widget__contact a {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: var(--white);
  background-color: var(--primary);
  padding: 13px 42px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 4px;
}

.widget__contact a span {
  position: absolute;
  width: 0px;
  height: 0px;
  background: var(--white);
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: -1;
}

.widget__contact a:hover {
  color: var(--secondary);
}

.widget__contact a:hover span {
  width: 300px;
  height: 300px;
}

.widget__download {
  background-color: #f5f5f5;
  padding: 30px;
  margin-bottom: 30px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget__download {
    padding: 30px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .widget__download {
    padding: 20px 10px;
  }
}

.widget__download ul {
  background-color: var(--white);
}

.widget__download ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--secondary);
  padding: 17px 20px;
  border-bottom: 1px solid #f5f5f5;
  border-radius: 3px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget__download ul li a {
    font-size: 14px;
    grid-gap: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .widget__download ul li a {
    font-size: 14px;
    grid-gap: 10px;
  }
}

.widget__download ul li a:hover {
  background-color: var(--primary);
  color: var(--white);
}

.widget__search {
  padding: 0 2px 30px;
  position: relative;
  z-index: 9;
}

.widget__search input {
  background-image: url(../imgs/icon/search.png);
  background-repeat: no-repeat;
  background-position: right;
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--gray-5);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--black-2);
  outline: none;
  padding: 10px 0;
  position: relative;
}

.widget__search input::-webkit-input-placeholder {
  opacity: 1;
}

.widget__search input::-moz-placeholder {
  opacity: 1;
}

.widget__search input:-ms-input-placeholder {
  opacity: 1;
}

.widget__search input::-ms-input-placeholder {
  opacity: 1;
}

.widget__search input::placeholder {
  opacity: 1;
}

.widget__category {
  margin-bottom: 30px;
  position: relative;
  z-index: 9;
}

.widget__category ul li {
  padding-bottom: 10px;
}

.widget__category ul li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--black-2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 20px;
}

.widget__category ul li a:hover {
  color: var(--primary);
}

.widget__recent-posts {
  margin-bottom: 30px;
  position: relative;
  z-index: 9;
}

.widget__recent-posts article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 10px;
}

@media only screen and (max-width: 767px) {
  .widget__recent-posts article {
    -webkit-box-pack: inherit;
    -ms-flex-pack: inherit;
    justify-content: inherit;
  }
}

.widget__recent-posts .rp-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--secondary);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .widget__recent-posts .rp-title {
    font-size: 16px;
  }
}

.widget__recent-posts .rp-date {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--black-2);
  padding-top: 5px;
}

.widget__recent-posts .rp-thumb {
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.widget__rpost {
  padding-bottom: 15px;
  position: relative;
  z-index: 9;
}

.widget__rpost a {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.widget__rpost a:hover .rp-title {
  color: var(--primary);
}

.widget__rpost a:hover .rp-thumb {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.widget__tags {
  margin-bottom: 30px;
}

.widget__tags li {
  display: inline-block;
  padding: 0 8px 8px 0;
}

.widget__tags li a {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--black-2);
  padding: 3px 10px;
  border: 1px solid var(--white-2);
  text-transform: capitalize;
  position: relative;
  z-index: 9;
}

.widget__tags li a:hover {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.widget__thumb {
  position: relative;
  z-index: 9;
}

.widget__thumb img {
  border-radius: 5px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .widget__thumb {
    margin-bottom: 30px;
  }
}

/*----------------------------------------*/
/*  07. ANIMATION CSS START
/*----------------------------------------*/
@-webkit-keyframes ripple-1 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes ripple-1 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@-webkit-keyframes moveBottom {
  0% {
    -webkit-transform: translateX(-25px);
    transform: translateX(-25px);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-25px);
    transform: translateX(-25px);
  }
}

@keyframes moveBottom {
  0% {
    -webkit-transform: translateX(-25px);
    transform: translateX(-25px);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-25px);
    transform: translateX(-25px);
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes wcLoading {
  0% {
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
    background: var(--white);
  }

  50% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    background: var(--primary);
  }

  100% {
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
    background: transparent;
  }
}

@keyframes wcLoading {
  0% {
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
    background: var(--white);
  }

  50% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    background: var(--primary);
  }

  100% {
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
    background: transparent;
  }
}

@-webkit-keyframes scroll {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(calc(-100% - 20px));
    transform: translateX(calc(-100% - 20px));
  }
}

@keyframes scroll {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(calc(-100% - 20px));
    transform: translateX(calc(-100% - 20px));
  }
}

@-webkit-keyframes wcfadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes wcfadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.wcfadeUp,
.wcfadeUp4,
.wcfadeUp3,
.wcfadeUp2 {
  opacity: 0;
  -webkit-animation-name: wcfadeUp;
  animation-name: wcfadeUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.wcfadeUp2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.wcfadeUp3 {
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
}

.wcfadeUp4 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

/*----------------------------------------*/
/*  08. SCROLL CSS START
/*----------------------------------------*/
.scroll-switch {
  position: fixed;
  width: auto;
  height: 300px;
  left: 50px;
  top: 0;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .scroll-switch {
    left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .scroll-switch {
    left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .scroll-switch {
    display: none;
  }
}

.scroll-bar,
.scroll-bar-2 {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: absolute;
  width: 140px;
  bottom: -170px;
  right: -50px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.scroll-bar button,
.scroll-bar-2 button {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: var(--black);
  background-color: transparent;
  border: none;
  padding-left: 10px;
}

.scroll-bar i,
.scroll-bar-2 i {
  -webkit-animation: moveBottom 5s infinite;
  animation: moveBottom 5s infinite;
  color: var(--gray-4);
}

.scroll-bar-2 {
  position: unset;
  margin-left: -22px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .scroll-bar-2 {
    margin-left: -37px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .scroll-bar-2 {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .scroll-bar-2 {
    display: none;
  }
}

/*----------------------------------------*/
/*  09. LOADING... CSS START
/*----------------------------------------*/
.preloader {
  width: 100%;
  height: 100vh;
  background-color: var(--white);
  position: relative;
  z-index: 999;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2;
}

.loading .bar {
  width: 6px;
  height: 60px;
  background: var(--white);
  display: inline-block;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-animation: wcLoading 1.5s ease-in-out infinite;
  animation: wcLoading 1.5s ease-in-out infinite;
}

.loading .bar1 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.loading .bar2 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.loading .bar3 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.loading .bar4 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.loading .bar5 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.loading .bar6 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.loading .bar7 {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.loading .bar8 {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

/*----------------------------------------*/
/*  10. MODAL CSS START
/*----------------------------------------*/
.modal {
  background-color: rgba(0, 0, 0, 0.75);
}

.modal-dialog__hero {
  margin-top: 100px;
  max-width: 900px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog__hero {
    max-width: 700px;
  }
}

.modal-content {
  height: 560px;
}

@media only screen and (max-width: 767px) {
  .modal-content {
    height: 320px;
  }
}

.modal-content iframe {
  height: 100%;
}

.modal .close-modal {
  background: var(--secondary);
  width: 30px;
  height: 30px;
  color: var(--white);
  position: absolute;
  right: -15px;
  top: -15px;
  border: none;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (max-width: 767px) {
  .modal .close-modal {
    right: -5px;
  }
}

.modal .close-modal:hover {
  background-color: var(--primary);
}

.video__area {
  background-image: url(../imgs/bg/video.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video__area {
    background-attachment: unset;
  }
}

@media only screen and (max-width: 767px) {
  .video__area {
    background-attachment: unset;
  }
}

.video__area:hover .play-now {
  opacity: 0;
  visibility: hidden;
}

.video__area .play-now {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: var(--white);
  background: var(--secondary);
  text-transform: capitalize;
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  width: 200px;
  height: 200px;
  line-height: 200px;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video__area .play-now {
    width: 150px;
    height: 150px;
    line-height: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .video__area .play-now {
    width: 120px;
    height: 120px;
    line-height: 120px;
  }
}

.video__content {
  display: block;
  width: 100%;
  height: 740px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video__content {
    height: 550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video__content {
    height: 550px;
  }
}

@media only screen and (max-width: 767px) {
  .video__content {
    height: 450px;
  }
}

.video__content button {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: none;
  background-color: transparent;
}

/*----------------------------------------*/
/* 11. HEADER CSS START
/*----------------------------------------*/
.header__area-3 {
  position: fixed;
  width: 100px;
  height: 100vh;
  left: 0;
  top: 0;
  background: var(--white);
  border-right: 1px solid var(--white-7);
  text-align: center;
  z-index: 99;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__area-3 {
    width: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__area-3 {
    border-bottom: 1px solid var(--white-7);
    width: 100%;
    height: 80px;
    line-height: 80px;
    padding: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .header__area-3 {
    border-bottom: 1px solid var(--white-7);
    width: 100%;
    height: 80px;
    line-height: 80px;
    padding: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__area-3 .side-navbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 767px) {
  .header__area-3 .side-navbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.header__top {
  background-color: var(--white-3);
  text-align: center;
  padding: 8px 15px;
}

@media only screen and (max-width: 767px) {
  .header__top {
    padding: 8px 30px;
  }
}

.header__top p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--secondary);
}

.header__top p span {
  background: var(--white-5);
  padding: 5px 8px;
  border-radius: 5px;
  margin-left: 10px;
}

.header__top .close-topbar {
  position: absolute;
  right: 20px;
  top: 7px;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .header__top .close-topbar {
    right: 10px;
    top: 5px;
  }
}

.header__info {
  padding: 0 90px;
  border-bottom: 1px solid var(--white-2);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .header__info {
    padding: 0 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header__info {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__info {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__info {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header__info {
    padding: 0;
  }
}

.header__address li {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--black-2);
  display: inline-block;
  padding-right: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__address li {
    padding-right: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .header__address li {
    padding: 3px 0;
  }
}

.header__address li span {
  margin-right: 10px;
}

.header__address li a {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--black-2);
  display: inline-block;
  border-left: 1px solid var(--white-2);
  padding: 10px 50px 10px 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__address li a {
    border: none;
    padding: 5px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header__address li a {
    display: block;
    padding: 0;
    border: none;
  }
}

.header__address li a:hover {
  color: var(--primary);
}

.header__info-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__info-right {
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .header__info-right {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 10px;
    border-top: 1px solid var(--white-2);
  }
}

.header__links {
  border-right: 1px solid var(--white-2);
  margin-right: 22px;
  padding-right: 30px;
}

.header__links li {
  padding-left: 25px;
  display: inline;
}

.header__links li a {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--black-2);
  text-transform: capitalize;
  display: inline-block;
  padding: 10px 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.header__links li a:hover {
  color: var(--primary);
}

.header__social li {
  display: inline-block;
}

.header__social li a {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--black-2);
  text-transform: capitalize;
  display: inline-block;
  padding: 10px 8px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.header__social li a:hover {
  color: var(--primary);
}

.header__social li a span {
  font-size: 16px;
}

.header__logo .logo-1 {
  opacity: 1;
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  border-radius: 0 0 5px 5px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header__logo .logo-1 {
    width: 220px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__logo .logo-1 {
    width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__logo .logo-1 {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .header__logo .logo-1 {
    width: 160px;
  }
}

.header__logo .logo-2 {
  top: 13px;
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (min-width: 1920px) {
  .header__logo .logo-2 {
    top: 16px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .header__logo .logo-2 {
    top: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header__logo .logo-2 {
    top: 15px;
  }
}

.header__logo img {
  position: absolute;
  z-index: 9;
  top: 0;
}

.header__logo-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.header__logo-3 {
  padding: 30px 0 135px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__logo-3 {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header__logo-3 {
    padding: 0;
  }
}

.header__menu-area {
  padding: 0 90px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-bottom: 1px solid var(--white);
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .header__menu-area {
    padding: 0 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header__menu-area {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__menu-area {
    padding: 10px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__menu-area {
    padding: 10px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header__menu-area {
    padding: 10px 0;
  }
}

.header__menu-area-2 {
  padding: 0 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__menu-area-2 {
    padding: 15px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__menu-area-2 {
    padding: 10px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header__menu-area-2 {
    padding: 10px 0;
  }
}

.header__menu-area.sticky {
  background-color: var(--white);
  border-bottom: 1px solid var(--white-7);
  position: relative;
  z-index: 99;
}

.header__menu-area.sticky .logo-1 {
  opacity: 0;
}

.header__menu-area.sticky .logo-2 {
  opacity: 1;
}

.header__nav {
  padding-right: 45px;
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header__nav {
    padding-right: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header__nav {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__nav {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header__nav {
    display: none;
  }
}

.header__nav ul {
  text-align: right;
}

.header__nav ul li {
  display: inline-block;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.header__nav ul li.has-megamenu {
  position: static;
}

.header__nav ul li:hover .main-dropdown {
  top: 76px;
  opacity: 1;
  visibility: visible;
  -webkit-transform: perspective(300px) rotateX(0deg);
  transform: perspective(300px) rotateX(0deg);
}

.header__nav ul li:hover .mega-menu {
  top: 76px;
  opacity: 1;
  visibility: visible;
  -webkit-transform: perspective(300px) rotateX(0deg);
  transform: perspective(300px) rotateX(0deg);
}

.header__nav ul li a {
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: var(--secondary);
  padding: 18px 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header__nav ul li a {
    padding: 18px 15px;
  }
}

.header__nav ul li a span {
  display: block;
  color: var(--black-2);
  font-size: 12px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.header__nav ul li a:hover {
  color: var(--primary);
}

.header__nav ul li a:hover span {
  color: var(--primary);
}

.header__nav .main-dropdown {
  position: absolute;
  width: 240px;
  left: 0;
  top: 100px;
  z-index: 9;
  background-color: var(--white);
  border: 1px solid var(--white-7);
  text-align: left;
  padding: 15px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: perspective(300px) rotateX(-18deg);
  transform: perspective(300px) rotateX(-18deg);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.header__nav .main-dropdown .sub-dropdown {
  position: absolute;
  width: 240px;
  left: 100%;
  top: 0;
  z-index: 9;
  background-color: var(--white);
  border: 1px solid var(--white-7);
  text-align: left;
  padding: 15px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-15px);
  transform: translateX(-15px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.header__nav .main-dropdown li {
  display: block;
}

.header__nav .main-dropdown li:hover .sub-dropdown {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(15px);
  transform: translateX(15px);
}

.header__nav .main-dropdown li a {
  display: block;
  padding: 10px 20px;
  border-radius: 5px;
  text-transform: capitalize;
}

.header__nav .main-dropdown li a:hover {
  background-color: var(--white-6);
}

.header__nav .mega-menu {
  position: absolute;
  width: 1150px;
  left: 0;
  top: 110px;
  z-index: 9;
  background-color: var(--white);
  border: 1px solid var(--white-7);
  text-align: left;
  padding: 30px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr 30px 1fr 30px 1fr 30px 1fr 30px 1fr;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: perspective(300px) rotateX(-18deg);
  transform: perspective(300px) rotateX(-18deg);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .header__nav .mega-menu {
    width: 1000px;
    -webkit-transform: perspective(300px) rotateX(-8deg);
    transform: perspective(300px) rotateX(-8deg);
    grid-gap: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header__nav .mega-menu {
    left: -30px;
    width: 990px;
    -webkit-transform: perspective(300px) rotateX(-6deg);
    transform: perspective(300px) rotateX(-6deg);
    grid-gap: 15px;
    padding: 30px 20px;
  }
}

.header__nav .mega-menu li a {
  font-size: 18px;
  font-weight: 500;
  padding: 10px;
  margin-bottom: 10px;
}

.header__nav .mega-menu li ul {
  text-align: left;
}

.header__nav .mega-menu li ul li {
  display: block;
}

.header__nav .mega-menu li ul li a {
  font-weight: 400;
  font-size: 16px;
  padding: 10px 12px;
  display: block;
  margin-bottom: 0;
}

.header__others {
  display: flex;
  align-items: center;
  grid-column-gap: 20px;
  height: 100%;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .header__others {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -webkit-box-pack: justify;
    /* -ms-flex-pack: justify; */
    justify-content: space-between;
  }
}

.header__search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 20px;
}

.header__search span {
  color: var(--secondary);
  font-size: 18px;
}

.header__switch {
  margin: auto;
}

.header__support {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media only screen and (max-width: 767px) {
  .header__support {
    display: none;
  }
}

.header__support p {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: right;
  margin-bottom: 0px;
  color: var(--black-2);
}

.header__support p a {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  color: var(--secondary);
}

.header__support p a:hover {
  color: var(--primary);
}

.header__lang {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.header__lang .lang {
  background: transparent;
  border: 1px solid var(--white-2);
  border-radius: 100%;
  width: 50px;
  height: 50px;
  padding: 5px;
  color: var(--secondary);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.header__lang .lang:hover {
  color: var(--primary);
  border-color: var(--primary);
}

.header__lang .dropdown-menu {
  min-width: 80px;
  padding: 0;
}

.header__lang .dropdown-menu li {
  border-radius: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--secondary);
  cursor: pointer;
  padding: 5px 10px;
  text-transform: capitalize;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.header__lang .dropdown-menu li:hover,
.header__lang .dropdown-menu li.active {
  color: var(--white);
  background-color: var(--primary);
}

.header__lang .dropdown-toggle::after {
  vertical-align: 0;
  content: "\f107";
  border: none;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  font-size: 14px;
}

.header__offcanvas {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.header__offcanvas span {
  width: 50px;
  height: 50px;
  border: 1px solid var(--white-2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  font-size: 25px;
  line-height: 22px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  color: var(--secondary);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.header__offcanvas span:hover {
  border: 1px solid var(--primary);
  color: var(--primary);
}

.header__side-nav {
  margin-bottom: 200px;
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__side-nav {
    margin-bottom: 0;
  }

  .header__offcanvas {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header__side-nav {
    margin-bottom: 0;
  }
}

.header__side-nav span {
  display: inline-block;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  font-size: 24px;
  color: var(--secondary);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.header__side-nav span:hover {
  color: var(--primary);
}

/* mean menu customize */
.side__navbar-wrapper.mean-container .mean-nav > ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}

.side__navbar-wrapper.mean-container .mean-nav > ul > li:last-child > a {
  border-bottom: 1px solid var(--white-7);
}

.side__navbar-wrapper.mean-container .mean-nav {
  background: none;
  margin-top: 0;
}

.side__navbar-wrapper.mean-container .mean-nav ul li a {
  width: 100%;
  padding: 30px 0;
  font-weight: 500;
  font-size: 60px;
  line-height: 1;
  color: var(--black);
  text-transform: capitalize;
  border-top: 1px solid var(--white-7);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .side__navbar-wrapper.mean-container .mean-nav ul li a {
    font-size: 48px;
  }

  .header__offcanvas {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .side__navbar-wrapper.mean-container .mean-nav ul li a {
    font-size: 40px;
  }

  .header__offcanvas {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .side__navbar-wrapper.mean-container .mean-nav ul li a {
    font-size: 36px;
    padding: 20px 0;
  }
}

@media only screen and (max-width: 767px) {
  .side__navbar-wrapper.mean-container .mean-nav ul li a {
    padding: 20px 0;
    font-size: 20px;
  }
}

.side__navbar-wrapper.mean-container .mean-nav ul li a:hover {
  color: var(--primary);
}

.side__navbar-wrapper.mean-container .mean-nav ul li a:hover span {
  color: var(--black);
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.side__navbar-wrapper.mean-container .mean-nav ul li a span {
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: right;
  color: var(--black-2);
  padding-right: 100px;
  opacity: 0;
  -webkit-transform: translateX(15px);
  transform: translateX(15px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .side__navbar-wrapper.mean-container .mean-nav ul li a span {
    padding-right: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .side__navbar-wrapper.mean-container .mean-nav ul li a span {
    font-size: 16px;
    padding-right: 70px;
  }
}

.side__navbar-wrapper.mean-container .mean-nav ul li a.mean-expand {
  width: 60px;
  height: 96px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 300;
  border-left: 1px solid var(--white-7) !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .side__navbar-wrapper.mean-container .mean-nav ul li a.mean-expand {
    height: 85px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .side__navbar-wrapper.mean-container .mean-nav ul li a.mean-expand {
    height: 76px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .side__navbar-wrapper.mean-container .mean-nav ul li a.mean-expand {
    height: 52px;
    width: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .side__navbar-wrapper.mean-container .mean-nav ul li a.mean-expand {
    height: 34px;
    width: 32px;
  }
}

.side__navbar-wrapper.mean-container .mean-nav ul li a.mean-expand:hover {
  background: var(--secondary);
  opacity: 1;
}

.side__navbar-wrapper.mean-container .mean-nav ul li li a {
  font-size: 24px;
  text-transform: capitalize;
  border-top: 1px solid var(--white-7);
  padding: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .side__navbar-wrapper.mean-container .mean-nav ul li li a {
    padding: 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .side__navbar-wrapper.mean-container .mean-nav ul li li a {
    font-size: 20px;
    padding: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .side__navbar-wrapper.mean-container .mean-nav ul li li a {
    font-size: 18px;
    padding: 15px 10px;
  }
}

.side__navbar-wrapper.mean-container .mean-nav ul li li a.mean-expand {
  height: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .side__navbar-wrapper.mean-container .mean-nav ul li li a.mean-expand {
    height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .side__navbar-wrapper.mean-container .mean-nav ul li li a.mean-expand {
    height: 22px;
  }
}

.side__navbar-wrapper.mean-container .mean-nav ul li li li a {
  padding-left: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .side__navbar-wrapper.mean-container .mean-nav ul li li li a {
    padding-left: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .side__navbar-wrapper.mean-container .mean-nav ul li li li a {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .side__navbar-wrapper.mean-container .mean-nav ul li li li a {
    padding-left: 30px;
  }
}

.side__navbar-wrapper.mean-container .mean-bar {
  padding: 0;
  background: none;
}

.side__navbar-wrapper.mean-container a.meanmenu-reveal {
  display: none !important;
}

.home-3-wrapper {
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-3-wrapper {
    padding-left: 69px;
  }

  .home-3-wrapper .line {
    overflow: hidden;
    padding: 100px 10px;
  }
}

/*----------------------------------------*/
/*  12. HERO CSS START
/*----------------------------------------*/
.hero__area {
  overflow: hidden;
}

.hero__area-2 {
  background-image: url(../imgs/icon/mirror1.avif);
  background-position: right center;
  background-repeat: no-repeat;
  overflow: auto;

  box-shadow: 0px 4px 4px 0px #ffffff, inset 0 0 0 994px rgb(232 220 220 / 50%);
  background-size: cover;
}

.cursor-pointer {
  cursor: pointer;
}

.hero__area-2 .line {
  padding: 240px 0;
  padding-top: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__area-2 .line {
    padding: 150px 0 240px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__area-2 .line {
    padding: 120px calc(var(--bs-gutter-x) * 0.5) 120px
      calc(var(--bs-gutter-x) * 0.5);
  }
}

.hero__content-2 {
  padding-bottom: 113px;
}

@media only screen and (max-width: 767px) {
  .hero__content-2 {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

.hero__content-2 h1 {
  font-weight: bold;
  font-size: 132px;
  line-height: 1;
  color: var(--secondary);
  padding-bottom: 20px;
  position: relative;
  z-index: 9;
  opacity: 0;
  -webkit-animation: wcfadeUp 1s 0.25s forwards;
  animation: wcfadeUp 1s 0.25s forwards;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero__content-2 h1 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__content-2 h1 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__content-2 h1 {
    font-size: 72px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__content-2 h1 {
    font-size: 36px;
  }
}

.hero__content-2 h1::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 1px;
  left: 0;
  bottom: -10px;
  background-color: var(--secondary);
}

@media only screen and (max-width: 767px) {
  .hero__content-2 h1::after {
    bottom: 10px;
  }
}

.hero__content-2 h1 #typed,
.hero__content-2 h1 .typed-cursor {
  color: var(--primary);
}

.hero__content-2 p {
  max-width: 660px;
  font-size: 18px;
  padding-left: 160px;
  padding-bottom: 45px;
  position: relative;
  z-index: 9;
  opacity: 0;
  -webkit-animation: wcfadeUp 1s 0.5s forwards;
  animation: wcfadeUp 1s 0.5s forwards;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .hero__content-2 p {
    padding-left: 0;
  }
}

.hero__content-2 a {
  margin-left: 160px;
  position: relative;
  z-index: 9;
  opacity: 0;
  -webkit-animation: wcfadeUp 1s 0.75s forwards;
  animation: wcfadeUp 1s 0.75s forwards;
}

@media only screen and (max-width: 767px) {
  .hero__content-2 a {
    margin-left: 0;
  }
}

.hero__content-2 a strong {
  width: 50px;
  display: inherit;
  height: 1px;
  background: #fff;
  margin-bottom: 5px;
  margin-left: 5px;
  position: relative;
}

.hero__content-2 a strong::after {
  position: absolute;
  content: "";
  top: -5px;
  right: 30px;
  border: solid var(--white);
  border-width: 0 1px 1px 0;
  padding: 5px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

.hero__content-2 a:hover strong::after {
  right: 0;
  opacity: 1;
}

/*----------------------------------------*/
/*  13. OFFCANVAS CSS START
/*----------------------------------------*/
.offcanvas__area {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  top: 0;
  left: 0;
  z-index: 99;
  -webkit-transform: perspective(300px) rotateY(18deg);
  transform: perspective(300px) rotateY(18deg);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.offcanvas__inner {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 3.14fr 60px 1fr;
  grid-template-columns: 3.14fr 1fr;
  grid-gap: 60px;
  height: 100%;
}

.footer__widget img {
  height: 100px;
  width: 100px;
}

.footer__widget .company_name {
  padding: 10px;
  color: white !important;
  text-align: center;
  font-weight: 500 !important;
  font-size: 25px !important;
}

@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .offcanvas__inner {
    -ms-grid-columns: 2fr 60px 1fr;
    grid-template-columns: 2fr 1fr;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__inner {
    -ms-grid-columns: 2fr 60px 1fr;
    grid-template-columns: 2fr 1fr;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__inner {
    -ms-grid-columns: 1.6fr 60px 1fr;
    grid-template-columns: 1.6fr 1fr;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__inner {
    -ms-grid-columns: 1.5fr 40px 1fr;
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .offcanvas__inner {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    padding: 50px 15px 20px;
  }
}

.offcanvas__left {
  border-right: 1px solid var(--white-7);
  padding: 85px 0 85px 100px;
  overflow: hidden;
  overflow-y: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__left {
    padding: 20px 0 0px 80px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__left {
    padding: 60px 0 60px 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__left {
    padding: 60px 0 40px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .offcanvas__left {
    padding: 0;
    border: none;
  }
}

.offcanvas__left::-webkit-scrollbar {
  width: 5px;
}

.offcanvas__left::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px var(--black-2);
  box-shadow: inset 0 0 5px var(--black-2);
  border-radius: 10px;
}

.offcanvas__left::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
}

.offcanvas__right {
  padding: 85px 100px 85px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
}

.offcanvas__right .offcanvas__logo {
  height: 200px;
  width: 200px;
}

.offcanvas__right .offcanvas__logo img {
  height: 100%;
  width: 100%;
}

.header__logo-2 {
  height: 85px;
  width: 85px;
}

.header__logo-2 img {
  height: 100%;
  width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__right {
    padding: 80px 80px 80px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__right {
    padding: 60px 60px 60px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__right {
    padding: 60px 30px 30px 0;
  }
}

@media only screen and (max-width: 767px) {
  .offcanvas__right {
    padding: 0;
    display: none;
  }
}

.offcanvas__contact li {
  padding-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__contact li {
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__contact li {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .offcanvas__contact li {
    padding-bottom: 20px;
  }
}

.offcanvas__contact li span {
  display: block;
  font-size: 16px;
  line-height: 28px;
  color: var(--black-2);
}

.offcanvas__contact li a {
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: var(--secondary);
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__contact li a {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__contact li a {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .offcanvas__contact li a {
    font-size: 18px;
  }
}

.offcanvas__contact li a::after {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  left: 0;
  bottom: 0px;
  background-color: var(--primary);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.offcanvas__contact li a:hover {
  color: var(--primary);
}

.offcanvas__contact li a:hover::after {
  width: 100%;
}

.offcanvas__contact li a:not([href]):not([class]) {
  position: unset;
}

.offcanvas__contact li:last-child {
  padding-bottom: 0;
}

.offcanvas__content {
  padding: 60px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .offcanvas__content {
    gap: 30px;
    padding: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__content {
    overflow-y: auto;
    padding: 30px 20px;
    gap: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__content {
    overflow-y: auto;
    padding: 30px 20px;
    gap: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .offcanvas__content {
    overflow-y: auto;
    padding: 30px 20px;
    gap: 50px;
  }
}

.offcanvas__search {
  padding-top: 30px;
}

.offcanvas__search input {
  width: 100%;
  height: 36px;
  outline: none;
  border: none;
  border-bottom: 1px solid var(--white-7);
  padding: 10px 0;
}

.offcanvas__map iframe {
  width: 100%;
  height: 220px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__map {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__map {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .offcanvas__map {
    display: none;
  }
}

.offcanvas__social li {
  display: inline-block;
}

.offcanvas__social li a {
  font-size: 20px;
  color: var(--black-2);
  display: block;
  padding-right: 20px;
}

.offcanvas__social li a:hover {
  color: var(--primary);
}

.offcanvas__mobile-menu {
  display: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas__mobile-menu {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas__mobile-menu {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .offcanvas__mobile-menu {
    display: block;
  }
}

.close_offcanvas {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 50px;
  top: 50px;
  font-size: 24px;
  color: var(--black-2);
  padding: 1px 11px;
  cursor: pointer;
  border: 1px solid var(--black-2);
  border-radius: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.close_offcanvas:hover {
  color: var(--primary);
  border-color: var(--primary);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .close_offcanvas {
    top: 20px;
    right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .close_offcanvas {
    top: 15px;
    right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .close_offcanvas {
    top: 10px;
    right: 25px;
  }
}

/*----------------------------------------*/
/*  14. BREADCRUMB CSS START
/*----------------------------------------*/
.breadcrumb__area {
  padding: 170px 0;
  padding-bottom: 70px;
  text-align: center;
  background-image: url(../../Assets/imgs/about/aboutMain.jpg);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
}

/* .breadcrumb__area_service {
  background-image: url('https://lnmgni.co.uk/services/') !important;
} */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb__area {
    padding: 160px 0;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb__area {
    /* padding: 100px 0; */

    padding: 20px 0;
    padding-top: 140px;
  }
}

.breadcrumb__area::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #040a1a;
  opacity: 0.8;
  z-index: -1;
}

.breadcrumb__content p {
  font-weight: 500;
  color: var(--white);
  opacity: 0;
  -webkit-animation: wcfadeUp 1s 0.3s forwards;
  animation: wcfadeUp 1s 0.3s forwards;
}

.breadcrumb__title {
  font-weight: 600;
  font-size: 80px;
  line-height: 1.2;
  color: var(--white);
  text-transform: capitalize;
  padding-bottom: 20px;
  opacity: 0;
  -webkit-animation: wcfadeUp 1s 0.15s forwards;
  animation: wcfadeUp 1s 0.15s forwards;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb__title {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb__title {
    font-size: 48px;
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb__title {
    font-size: 36px;
    padding-bottom: 10px;
  }
}

/*----------------------------------------*/
/* 15. SLIDER CSS START
/*----------------------------------------*/
.hero__area-3 {
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .hero__area-3 .line {
    padding: 0;
  }
}

.hero__slider-3 {
  overflow: unset;
}

.hero__slider-3-thumbs {
  position: absolute;
  width: 530px;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-color: var(--white);
  padding: 25px 30px 25px 40px;
  border-radius: 5px 0 0 0;
  opacity: 0;
  -webkit-animation: wcfadeUp 1s 0.6s forwards;
  animation: wcfadeUp 1s 0.6s forwards;
}

@media only screen and (max-width: 767px) {
  .hero__slider-3-thumbs {
    width: 350px;
    padding: 20px;
  }
}

.hero__slider-3-thumbs p {
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: var(--black-2);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (max-width: 767px) {
  .hero__slider-3-thumbs p {
    font-size: 14px;
    line-height: 18px;
  }
}

.hero__slider-3-thumbs p:hover {
  color: var(--secondary);
}

.hero__slider-3-thumbs p span {
  display: block;
  padding-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .hero__slider-3-thumbs p span {
    padding-bottom: 5px;
  }
}

.hero__slider-3-thumbs .swiper-wrapper {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.hero__slider-3-thumbs .swiper-slide-thumb-active p {
  color: var(--secondary);
  position: relative;
}

.hero__slider-3-thumbs .swiper-slide-thumb-active p::before {
  position: absolute;
  content: "\f0d8";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  left: -30px;
  top: -18px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  color: var(--primary);
  font-size: 20px;
}

@media only screen and (max-width: 767px) {
  .hero__slider-3-thumbs .swiper-slide-thumb-active p::before {
    left: -16px;
  }
}

.hero__slide {
  padding: 208px 0 160px;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
}

.hero__slide.swiper-slide-active h1 {
  -webkit-animation: wcfadeUp 1s 0.5s forwards;
  animation: wcfadeUp 1s 0.5s forwards;
}

.hero__slide.swiper-slide-active p {
  -webkit-animation: wcfadeUp 1s 0.75s forwards;
  animation: wcfadeUp 1s 0.75s forwards;
}

.hero__slide.swiper-slide-active a {
  -webkit-animation: wcfadeUp 1s 0.9s forwards;
  animation: wcfadeUp 1s 0.9s forwards;
}

.hero__slide-3 {
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 430px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__slide-3 {
    padding-bottom: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__slide-3 {
    padding-bottom: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__slide-3 {
    padding-bottom: 200px;
  }
}

.hero__slide-3.swiper-slide-active span.hero__sub-title-3 {
  -webkit-animation: wcfadeUp 1s 0.25s forwards;
  animation: wcfadeUp 1s 0.25s forwards;
}

.hero__slide-3.swiper-slide-active h1 {
  -webkit-animation: wcfadeUp 1s 0.5s forwards;
  animation: wcfadeUp 1s 0.5s forwards;
}

.hero__slide-3.swiper-slide-active p {
  -webkit-animation: wcfadeUp 1s 0.75s forwards;
  animation: wcfadeUp 1s 0.75s forwards;
}

.hero__slide-3.swiper-slide-active a {
  -webkit-animation: wcfadeUp 1s 1s forwards;
  animation: wcfadeUp 1s 1s forwards;
}

.hero__slide-left h1 {
  font-weight: 600;
  font-size: 92px;
  line-height: 1;
  color: var(--secondary);
  padding-bottom: 15px;
  opacity: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__slide-left h1 {
    font-size: 72px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__slide-left h1 {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__slide-left h1 {
    font-size: 40px;
  }
}

.hero__slide-left h1 span {
  display: block;
  color: var(--primary);
}

.hero__slide-left p {
  max-width: 500px;
  padding-bottom: 35px;
  font-size: 18px;
  color: var(--secondary);
  opacity: 0;
}

.hero__slide-left a {
  opacity: 0;
}

.hero__slide-left-3 {
  margin-top: -110px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__slide-left-3 {
    margin-top: -50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__slide-left-3 {
    margin-top: 120px;
    padding: 0 15px;
  }
}

.hero__slide-left-3 h1 {
  font-weight: 600;
  font-size: 90px;
  line-height: 1;
  color: var(--secondary);
  padding-bottom: 17px;
  padding-top: 20px;
  opacity: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero__slide-left-3 h1 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__slide-left-3 h1 {
    font-size: 72px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__slide-left-3 h1 {
    font-size: 48px;
  }
}

.hero__slide-left-3 p {
  max-width: 480px;
  font-size: 18px;
  color: var(--secondary);
  padding-bottom: 40px;
  opacity: 0;
}

.hero__slide-left-3 a {
  border-radius: 5px;
  opacity: 0;
}

.hero__slide-left-3 span.hero__sub-title-3 {
  opacity: 0;
}

.hero__slide-right {
  position: relative;
}

.hero__sub-title-3 {
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--secondary);
  position: relative;
  padding-left: 50px;
}

.hero__sub-title-3::before {
  position: absolute;
  content: "";
  width: 40px;
  height: 1px;
  left: 0;
  top: 7px;
  background-color: var(--secondary);
}

.hero__slider-pagination {
  width: 345px;
  background: var(--white);
  height: 90px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 9;
  opacity: 0;
  -webkit-animation: wcfadeUp 1s 0.6s forwards;
  animation: wcfadeUp 1s 0.6s forwards;
}

@media only screen and (max-width: 767px) {
  .hero__slider-pagination {
    width: 300px;
    height: 70px;
  }
}

.hero__slider-pagination .swiper-button-prev,
.hero__slider-pagination .swiper-button-next {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--secondary);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.hero__slider-pagination .swiper-button-prev:hover,
.hero__slider-pagination .swiper-button-next:hover {
  color: var(--primary);
}

.hero__slider-pagination .swiper-button-prev::after,
.hero__slider-pagination .swiper-button-prev::after,
.hero__slider-pagination .swiper-button-next::after,
.hero__slider-pagination .swiper-button-next::after {
  display: none;
}

.hero__slider-pagination .swiper-button-prev span,
.hero__slider-pagination .swiper-button-next span {
  padding: 0 10px;
}

.hero__slider-pagination .swiper-button-next {
  right: 50px;
}

.hero__slider-pagination .swiper-button-prev {
  left: 50px;
}

.hero__slider-pagination .swiper-pagination-fraction {
  bottom: 0;
  left: 50%;
  width: 80px;
  margin-left: -40px;
}

.hero__slider-pagination .swiper-pagination {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--black-2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  gap: 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero__slider-pagination .swiper-pagination-current {
  font-size: 50px;
  color: var(--secondary);
}

@media only screen and (max-width: 767px) {
  .hero__slider-pagination .swiper-pagination-current {
    font-size: 40px;
  }
}

.hero__slider-pagination-3 {
  width: 345px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__slider-pagination-3 {
    width: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__slider-pagination-3 {
    width: 100px;
    top: 135px;
  }
}

.hero__slider-pagination-3 .swiper-pagination {
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: var(--secondary);
  height: 35px;
}

.hero__slider-pagination-3 .swiper-pagination-current {
  font-weight: 300;
  font-size: 110px;
  position: absolute;
  right: 53%;
  top: 30px;
}

@media only screen and (max-width: 767px) {
  .hero__slider-pagination-3 .swiper-pagination-current {
    font-size: 90px;
    top: 20px;
    right: 60%;
  }
}

.play-btn {
  position: absolute;
  width: 120px;
  height: 120px;
  right: -20px;
  top: 108px;
  z-index: 9;
}

.play-btn:hover i {
  color: var(--secondary);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .play-btn {
    right: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .play-btn {
    right: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .play-btn {
    right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .play-btn {
    height: 80px;
    width: 80px;
    right: 50px;
    top: unset;
    bottom: 25px;
  }
}

.play-btn button {
  font-size: 30px;
  color: var(--primary);
  position: absolute;
  left: 0;
  top: 0;
  width: 120px;
  height: 120px;
  border: none;
  border-radius: 100%;
  background-color: var(--white);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.play-btn button i {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.play-btn button::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--white);
  top: 0;
  left: 0;
  border-radius: 50%;
  -webkit-animation: ripple-1 2s 0.5s infinite;
  animation: ripple-1 2s 0.5s infinite;
  z-index: -1;
}

.play-btn button::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--white);
  top: 0;
  left: 0;
  border-radius: 50%;
  -webkit-animation: ripple-1 2s 1s infinite;
  animation: ripple-1 2s 1s infinite;
  z-index: -1;
}

/*----------------------------------------*/
/* 16. ABOUT US CSS START
/*----------------------------------------*/
.about__area {
  background-image: url(../imgs/about/bg-1.png);
  background-position: right bottom;
  background-repeat: no-repeat;
  overflow: hidden;
}

.about__area .line {
  padding: 130px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__area .line {
    padding: 90px 0;
  }
}

@media only screen and (max-width: 767px) {
  .about__area .line {
    padding: 60px calc(var(--bs-gutter-x) * 0.5) 60px
      calc(var(--bs-gutter-x) * 0.5);
  }
}

.about__area-2 {
  background-image: url(../imgs/about/bg-2.png);
  background-color: var(--secondary);
}

.about__area-2 .line {
  overflow: hidden;
}

.about__area-2 .line::before,
.about__area-2 .line::after,
.about__area-2 .line-3,
.about__area-2 .line-4,
.about__area-2 .line-5 {
  background: var(--gray-2);
}

.about__area-2 .section-sub-title-2::after {
  background-color: var(--white);
}

.about__area-3 {
  overflow: hidden;
}

.about__area-3 .line {
  padding-bottom: 130px;
}

@media only screen and (max-width: 767px) {
  .about__area-3 .line {
    padding-bottom: 60px;
  }
}

.about__area-4 {
  overflow: hidden;
}

.about__area-4 .line {
  padding-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
  }
}

.about__left-4 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 30px;
  padding-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__left-4 {
    grid-gap: 15px;
    padding-top: 80px;
  }
}

.about__left-4-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 30px;
  opacity: 0;
  -webkit-animation: wcfadeUp 1s 0.6s forwards;
  animation: wcfadeUp 1s 0.6s forwards;
}

.about__left-4 img {
  width: 265px;
  border-radius: 5px;
}

.about__left-4-img-2 img {
  width: 245px;
  border-radius: 5px;
}

.about__right {
  max-width: 570px;
  padding-left: 80px;
  padding-top: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__right {
    padding-left: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__right {
    padding-left: 0;
    padding-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .about__right {
    padding-left: 0;
    padding-top: 60px;
  }

  .about__left-4 {
    display: none;
  }
}

.about__right > p {
  padding-top: 20px;
  padding-bottom: 40px;
}

.about__right-2 {
  padding-top: 45px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__right-2 {
    padding-top: 0;
  }
}

.about__right-2 p {
  padding-top: 5px;
}

.about__right-3 {
  padding-top: 80px;
  padding-left: 90px;
  max-width: 590px;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__right-3 {
    padding-left: 60px;
    padding-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__right-3 {
    padding-left: 30px;
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .about__right-3 {
    padding-left: 0;
    padding-top: 50px;
  }
}

.about__right-3 > p {
  padding-top: 30px;
  padding-bottom: 5px;
}

.about__right-3 > p strong {
  color: var(--secondary);
  font-weight: 500;
}

.about__right-4 {
  padding-right: 80px;
  padding-top: 60px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__right-4 {
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__right-4 {
    padding-right: 30px;
    padding-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .about__right-4 {
    padding-right: 0;
    padding-top: 0;
  }
}

.about__right-4-text {
  padding-bottom: 5px;
  padding-top: 20px;
}

.about__right-4 .section-sub-title {
  opacity: 0;
  -webkit-animation: wcfadeUp 1s 0.45s forwards;
  animation: wcfadeUp 1s 0.45s forwards;
}

.about__right-4 .section-title {
  opacity: 0;
  -webkit-animation: wcfadeUp 1s 0.6s forwards;
  animation: wcfadeUp 1s 0.6s forwards;
}

.about__counter,
.about__counter-3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 30px;
  max-width: 415px;
  padding-top: 30px;
}

@media only screen and (max-width: 767px) {
  .about__counter,
  .about__counter-3 {
    gap: 15px;
  }
}

.about__counter-title,
.about__counter-title-3 {
  font-weight: 400;
  font-size: 16px;
  color: var(--secondary);
  line-height: 1.4;
}

@media only screen and (max-width: 767px) {
  .about__counter-title br,
  .about__counter-title-3 br {
    display: none;
  }
}

.about__counter-3 {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 30px;
  border-top: 1px solid var(--white-7);
  border-bottom: 1px solid var(--white-7);
  max-width: unset;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__counter-3 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 767px) {
  .about__counter-3 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.about__content-3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 15px;
}

.about__content-3 button {
  width: 60px;
  height: 60px;
  background: transparent;
  border: 1px solid var(--white-4);
  border-radius: 50%;
  color: var(--primary);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.about__content-3 button:hover {
  border-color: var(--primary);
}

.about__content-3 p {
  color: var(--secondary);
}

.about__number {
  font-weight: 400;
  font-size: 60px;
  line-height: 1.3;
  color: var(--secondary);
}

@media only screen and (max-width: 767px) {
  .about__number {
    font-size: 48px;
  }
}

.about__number-3 {
  font-weight: 500;
}

.about__experience {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 60px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 45px;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__experience {
    gap: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__experience {
    gap: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .about__experience {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 20px;
  }
}

.about__experience p {
  max-width: 330px;
  color: var(--black-4);
}

.about__experience h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: var(--black-4);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__experience h4 {
    font-size: 18px;
  }
}

.about__experience h4 span {
  display: block;
  font-weight: 400;
  font-size: 80px;
  line-height: 1.1;
  color: var(--white);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__experience h4 span {
    font-size: 60px;
  }
}

.about__thumb {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 100%;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
}

.about__thumb-2 {
  text-align: right;
  position: relative;
  z-index: 1;
  padding: 20px;
  padding-top: 50px;
}

@media only screen and (max-width: 767px) {
  .about__thumb-2 {
    text-align: center;
  }
}

.about__thumb-2 img {
  width: 100%;
  margin-top: -230px;
  border-radius: 5px;
}

@media only screen and (max-width: 767px) {
  .about__thumb-2 img {
    margin-top: -60px;
  }
}

.about__thumb-2 .play-btn {
  left: calc(50% - 60px);
  top: calc(50% - 185px);
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .about__thumb-2 .play-btn {
    left: calc(50% - 30px);
    top: calc(50% - 60px);
  }

  .about__thumb-2 .play-btn button {
    width: 60px;
    height: 60px;
    font-size: 20px;
  }
}

.about__thumb-3 {
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 100%;
  border-radius: 5px;
}

.about__awards ul li {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.7fr 5fr 1fr;
  grid-template-columns: 0.7fr 5fr 1fr;
  color: var(--black-4);
  border-top: 1px solid var(--gray-2);
  padding: 15px 0;
}

.about__awards ul li:last-child {
  border-bottom: 1px solid var(--gray-2);
}

.about__img4-1 {
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  min-height: 300px;
}

.about__img4-2 {
  margin-top: 4rem;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  min-height: 300px;
}

.about__img4-3 {
  -webkit-transform: translateY(10px);
  transform: translateY(-10px);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  width: 265px;
  min-height: 300px;
}

/*----------------------------------------*/
/* 17. SERVICE CSS START
/*----------------------------------------*/
.service__area {
  background-color: #f9f9f9;
  overflow: hidden;
}

.service__area .line {
  padding: 130px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__area .line {
    padding: 90px 0;
  }
}

@media only screen and (max-width: 767px) {
  .service__area .line {
    padding: 60px calc(var(--bs-gutter-x) * 0.5) 60px
      calc(var(--bs-gutter-x) * 0.5);
  }
}

.service__text {
  max-width: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  height: 100%;
  margin-left: auto;
}

.service__text p {
  padding-bottom: 20px;
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .service__text p {
    padding-top: 20px;
  }
}

.service__text p span {
  font-weight: 500;
  color: var(--secondary);
}

.service__slider-wrapper {
  padding-top: 60px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .service__slider-wrapper {
    padding-top: 30px;
  }
}

.service__slide:hover {
  -webkit-box-shadow: 0px 20px 30px rgba(18, 23, 40, 0.05);
  box-shadow: 0px 20px 30px rgba(18, 23, 40, 0.05);
}

.service__slide:hover .service__thumb {
  -webkit-transform: scale(1.07);
  transform: scale(1.07);
}

.service__slide:hover h4 {
  color: var(--primary);
}

.service__slide-4 {
  padding-bottom: 50px;
  position: relative;
  z-index: 9;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.service__slide-4:hover {
  -webkit-box-shadow: 0px 20px 30px rgba(18, 23, 40, 0.05);
  box-shadow: 0px 20px 30px rgba(18, 23, 40, 0.05);
}

.service__slide-4:hover .service__thumb {
  -webkit-transform: scale(1.07);
  transform: scale(1.07);
  border-radius: 5px;
}

.service__slide-4:hover h4 {
  color: var(--primary);
}

.service__thumb {
  width: 100%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.service__thumb-wrapper {
  height: 475px;
  width: 80%;
  margin: auto;
  overflow: hidden;
  border-radius: 5px;
}

.service__slide-title {
  padding: 24px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--white);
  width: 310px;
  margin: 0 auto;
  margin-top: -62px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__slide-title {
    width: 305px;
    margin-top: -10px;
    padding: 20px;
  }
}

.service__slide-title h4 {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: var(--secondary);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__slide-title h4 {
    font-size: 20px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .service__slide-title h4 {
    font-size: 20px;
    line-height: 24px;
  }
}

.service__btm,
.service__btm-4 {
  text-align: center;
  padding-top: 70px;
}

@media only screen and (max-width: 767px) {
  .service__btm,
  .service__btm-4 {
    padding-top: 50px;
  }
}

.service__btm p,
.service__btm-4 p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  display: inline-block;
  padding: 6px 20px;
  color: var(--black-2);
  background: var(--white-6);
  border: 1px solid var(--gray);
  border-radius: 50px;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .service__btm p,
  .service__btm-4 p {
    line-height: 20px;
  }
}

.service__btm p a,
.service__btm-4 p a {
  padding-left: 3px;
  color: var(--secondary);
  font-weight: 500;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.service__btm p a:hover,
.service__btm-4 p a:hover {
  color: var(--primary);
}

.service__btm p a:hover i,
.service__btm-4 p a:hover i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  color: var(--primary);
}

.service__btm p a i,
.service__btm-4 p a i {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.service__btm-4 {
  padding-top: 20px;
}

@media only screen and (max-width: 767px) {
  .service__btm-4 {
    padding-top: 0;
  }
}

.service__btn-next {
  position: absolute;
  top: calc(50% - 3px);
  left: -90px;
  z-index: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__btn-next {
    left: -70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__btn-next {
    left: -10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__btn-next {
    left: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .service__btn-next {
    left: 0;
  }
}

.service__btn-next span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 60px;
  background: #fff;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  color: var(--black-2);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (max-width: 767px) {
  .service__btn-next span {
    width: 40px;
    height: 40px;
  }
}

.service__btn-next span:hover {
  color: var(--white);
  background-color: var(--primary);
}

.service__btn-prev {
  position: absolute;
  top: calc(50% - 3px);
  right: -90px;
  z-index: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__btn-prev {
    right: -70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__btn-prev {
    right: -10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__btn-prev {
    right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .service__btn-prev {
    right: 0;
  }
}

.service__btn-prev span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 60px;
  background: var(--white);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  color: var(--black-2);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (max-width: 767px) {
  .service__btn-prev span {
    width: 40px;
    height: 40px;
  }
}

.service__btn-prev span:hover {
  color: var(--white);
  background-color: var(--primary);
}

.service__item {
  padding: 40px;
  background: var(--primary);
  border-radius: 6px;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__item {
    padding: 30px 10px;
    background-color: transparent;
  }
}

@media only screen and (max-width: 767px) {
  .service__item {
    padding: 40px 25px;
    margin-bottom: 30px;
    background-color: transparent;
  }
}

.service__item::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: var(--white);
  z-index: -1;
  border-radius: 5px;
  left: 0;
  top: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.service__item-inner {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.service__item-inner img {
  height: 250px;
  width: 100%;
}

.service__item:hover::after {
  left: -10px;
  top: -10px;
}

.service__item:hover .service__item-inner {
  margin-top: -10px;
  margin-left: -10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.service__item:hover .service__rm-3 {
  color: var(--primary);
}

.service__item:hover .service__rm-3 i {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.service__item img {
  padding-bottom: 20px;
}

.service__item p {
  padding-bottom: 20px;
}

.service__rm-3 {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: var(--secondary);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.service__rm-3 i {
  padding-left: 3px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.service__title-3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.1;
  color: var(--black);
  padding-bottom: 20px;
}

.service__title-wrapper-3 {
  padding-bottom: 65px;
  text-align: center;
  position: relative;
}

.service__title-wrapper-3 p {
  max-width: 515px;
  margin: 0 auto;
  padding-top: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__sidebar {
    padding-right: 60px;
  }
}

.service__detail {
  overflow: hidden;
  padding-top: 20px;
}

.service__detail-content {
  padding-left: 80px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .service__detail-content {
    padding-left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__detail-content {
    padding-left: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__detail-content {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .service__detail-content {
    padding-left: 0;
  }
}

.service__detail-content .feature__list {
  padding-top: 5px;
  max-width: 510px;
  padding-bottom: 20px;
}

.service__detail-content .feature__list li {
  color: var(--black-2);
  position: relative;
  z-index: 9;
}

.service__detail-content p {
  padding-bottom: 15px;
  position: relative;
  z-index: 9;
}

.service__detail-content img {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 9;
}

.service__detail-content .thumb-col-2 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 60px 1fr;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 60px;
  padding: 20px 0 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__detail-content .thumb-col-2 {
    grid-gap: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__detail-content .thumb-col-2 {
    grid-gap: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .service__detail-content .thumb-col-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
}

.service__detail-content .thumb-col-2 img {
  width: 100%;
}

.service__detail-thumb {
  margin-top: 15px !important;
  width: 85%;
  border-radius: 5px;
  padding-bottom: 45px;
  padding-top: 10px;
  position: relative;
  z-index: 9;
  margin: auto;
  display: flex;
  height: 600px;
}

.service__detail-title {
  font-weight: 600;
  font-size: 36px;
  line-height: 1.2;
  color: var(--secondary);
  padding-bottom: 20px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__detail-title {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__detail-title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .service__detail-title {
    font-size: 24px;
  }
}

.service__detail-sub-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: var(--secondary);
  padding-bottom: 15px;
  padding-top: 5px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__detail-sub-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .service__detail-sub-title {
    font-size: 20px;
  }
}

.service__v2 .service__item {
  margin-bottom: 25px;
}

.service__v2 .service__btm-4 {
  padding-top: 50px;
}

/*----------------------------------------*/
/* 18. COUNTER CSS START
/*----------------------------------------*/
.counter__area {
  background-image: url(../imgs/bg/counter-bg.png);
  background-color: var(--secondary);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.counter__area .line {
  padding-bottom: 375px;
}

@media only screen and (max-width: 767px) {
  .counter__area .line {
    padding-top: 60px;
    padding-bottom: 0px;
  }
}

.counter__area .line::after,
.counter__area .line::before,
.counter__area .line-3,
.counter__area .line-4,
.counter__area .line-5 {
  background: var(--gray-2);
}

.counter__area-2 {
  background-image: url(../imgs/bg/counter-bg-2.png);
  background-color: var(--primary);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .counter__area-2 .line {
    padding-bottom: 10px;
  }
}

.counter__area-2 .line::after,
.counter__area-2 .line::before,
.counter__area-2 .line-3,
.counter__area-2 .line-4,
.counter__area-2 .line-5 {
  background-color: #fb9750;
}

.counter__content {
  text-align: center;
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .counter__content {
    padding-bottom: 60px;
  }
}

.counter__content-2 {
  text-align: center;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter__content-2 {
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .counter__content-2 {
    padding-bottom: 60px;
  }
}

.counter__content-2 p {
  color: var(--white);
}

.counter__number {
  font-weight: 400;
  font-size: 80px;
  line-height: 1.1;
  color: var(--white);
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter__number {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .counter__number {
    font-size: 60px;
  }
}

/*----------------------------------------*/
/* 19. CASE STUDY CSS START
/*----------------------------------------*/
.case-study__area-2 {
  overflow: hidden;
  min-height: 500px;
}

.case-study__area-2 .line {
  padding-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .case-study__area-2 .line {
    padding-bottom: 40px;
  }
}

.case-study__area-3 .line {
  padding-bottom: 60px;
}

.case-study__area-4 {
  overflow: hidden;
}

.case-study__area-4 .line {
  padding-bottom: 65px;
}

.case-study__area-4 .line.remove-pb {
  padding-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .case-study__area-4 .line {
    padding-bottom: 40px;
  }
}

.case-study__area-4 .case-study__slider-3 {
  margin-left: 0;
}

.case-study__wrapper {
  position: relative;
  border: 1px solid var(--white-7);
  background-color: var(--white);
  margin-top: -250px;
  z-index: 9;
  padding-right: 0;
}

@media only screen and (max-width: 767px) {
  .case-study__wrapper {
    margin-top: 0;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }
}

.case-study__text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  height: 100%;
  padding-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .case-study__text {
    padding-top: 25px;
    padding-bottom: 0;
  }
}

.case-study__text p {
  max-width: 470px;
  position: relative;
  z-index: 9;
}

.case-study__text p strong {
  font-weight: 500;
  color: var(--secondary);
}

.case-study__content {
  max-width: 435px;
  margin: 0 auto;
  padding-top: 110px;
}

@media only screen and (max-width: 767px) {
  .case-study__content {
    padding-top: 60px;
  }
}

.case-study__content p {
  padding-top: 20px;
  padding-bottom: 30px;
}

.case-study__features {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 20px;
}

.case-study__features p {
  padding-top: 15px;
}

.case-study__feature h3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: var(--secondary);
  padding-top: 5px;
}

.case-study__feature h4 {
  font-weight: 500;
  font-size: 28px;
  line-height: 1.1;
  color: var(--primary);
}

.case-study__img {
  position: relative;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .case-study__img {
    padding-left: 10px;
  }
}

.case-study__img img {
  width: 100%;
}

.case-study__img .support {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 25px 30px;
  gap: 20px;
  width: 280px;
  position: absolute;
  bottom: 0;
  right: -7px;
  background-color: var(--white);
}

.case-study__img .support img {
  width: auto;
}

.case-study__img .support p {
  font-size: 12px;
}

.case-study__img .support p a {
  display: block;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  color: var(--secondary);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.case-study__img .support p a:hover {
  color: var(--primary);
}

.case-study__slider-3 {
  width: 100%;
  height: 100%;
  margin-left: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .case-study__slider-3 {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .case-study__slider-3 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .case-study__slider-3 {
    margin-left: 0;
  }
}

.case-study__slide-2:hover .case-study__info-2 {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.case-study__slide-2 img {
  width: 100%;
  height: 430px;
  border-radius: 5px;
}

.case-study__slider-new img {
  width: 360px;
  height: 400px;
  border-radius: 5px;
}

.case-study__slide-3 {
  width: 50% !important;
  height: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .case-study__slide-3 {
    height: 400px;
    width: 60% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .case-study__slide-3 {
    height: 400px;
    width: 60% !important;
  }
}

@media only screen and (max-width: 767px) {
  .case-study__slide-3 {
    height: 400px;
    width: 100% !important;
  }
}

.case-study__slide-3:nth-child(2n) {
  width: 25% !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .case-study__slide-3:nth-child(2n) {
    width: 40% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .case-study__slide-3:nth-child(2n) {
    width: 40% !important;
  }
}

@media only screen and (max-width: 767px) {
  .case-study__slide-3:nth-child(2n) {
    width: 100% !important;
  }
}

.case-study__slide-3:nth-child(3n) {
  width: 25% !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .case-study__slide-3:nth-child(3n) {
    width: 40% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .case-study__slide-3:nth-child(3n) {
    width: 40% !important;
  }
}

@media only screen and (max-width: 767px) {
  .case-study__slide-3:nth-child(3n) {
    width: 100% !important;
  }
}

.case-study__slide-3:hover .case-study__info-3 {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.case-study__slide-3 img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.case-study__slide-3 .cs-slide3-inner {
  overflow: hidden;
  position: relative;
}

.case-study__info-2,
.case-study__info-3 {
  width: 270px;
  background-color: var(--white);
  right: 0;
  padding: 10px 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .case-study__info-2,
  .case-study__info-3 {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .case-study__info-2,
  .case-study__info-3 {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@media only screen and (max-width: 767px) {
  .case-study__info-2,
  .case-study__info-3 {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.case-study__info-2:hover::after,
.case-study__info-3:hover::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.case-study__info-2 h4,
.case-study__info-3 h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--secondary);
}

.case-study__info-2 p,
.case-study__info-3 p {
  font-size: 13px;
}

.case-study__info-2 p span,
.case-study__info-3 p span {
  color: var(--secondary);
}

.case-study__tab-btns {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

@media only screen and (max-width: 767px) {
  .case-study__tab-btns {
    margin-top: 20px;
  }
}

.case-study__tab-btns button {
  background-color: transparent;
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--black-2);
  text-align: left;
  padding-left: 30px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .case-study__tab-btns button {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .case-study__tab-btns button {
    padding: 0 20px 20px 0;
  }
}

.case-study__tab-btns button:hover,
.case-study__tab-btns button.active {
  color: var(--primary);
}

.case-study__tab-btns button span {
  display: block;
  font-size: 12px;
}

/*----------------------------------------*/
/* 20. FAQ CSS START
/*----------------------------------------*/
.faq__area {
  overflow: hidden;
}

.faq__area .line {
  padding: 130px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq__area .line {
    padding: 90px 0;
  }
}

@media only screen and (max-width: 767px) {
  .faq__area .line {
    padding: 60px calc(var(--bs-gutter-x) * 0.5) 50px
      calc(var(--bs-gutter-x) * 0.5);
  }
}

.faq__left {
  padding-left: 60px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq__left {
    padding-left: 0;
    padding-right: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
  }
}

.faq__left img {
  border-radius: 5px;
  width: 100%;
}

.faq__from {
  background-color: var(--primary);
  width: 300px;
  padding: 20px 60px 8px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: absolute;
  left: -138px;
  top: 50%;
  margin-top: -75px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq__from {
    width: 260px;
    left: -70px;
  }
}

@media only screen and (max-width: 767px) {
  .faq__from {
    width: 260px;
    left: -140px;
  }
}

.faq__from span {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--white);
  position: relative;
}

.faq__from span::after {
  position: absolute;
  content: "";
  width: 75px;
  height: 1px;
  left: 60px;
  top: 10px;
  background-color: var(--white);
}

.faq__from h2 {
  font-weight: 400;
  font-size: 80px;
  line-height: 1.1;
  color: var(--white);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq__from h2 {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .faq__from h2 {
    font-size: 60px;
  }
}

.faq__right {
  padding-left: 85px;
  padding-top: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq__right {
    padding-left: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq__right {
    padding-left: 15px;
    padding-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .faq__right {
    padding-left: 0;
  }
}

.faq__list {
  padding-top: 30px;
}

.faq__list .accordion-body {
  padding-top: 30px;
  padding-right: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq__list .accordion-body {
    padding-top: 20px;
    padding-right: 0;
  }
}

.faq__list .accordion-item {
  border: none;
  border-bottom: 1px solid var(--white-7);
  border-radius: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq__list .accordion-item {
    margin-bottom: 0;
  }
}

.faq__list-2 .accordion-item {
  border: none;
}

.faq__title,
.faq__title-2 {
  display: block;
  position: relative;
  z-index: 9;
}

.faq__title button,
.faq__title-2 button {
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--white);
  background: var(--secondary);
  width: 100%;
  text-align: left;
  padding: 15px 25px;
  position: relative;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.faq__title button.collapsed,
.faq__title-2 button.collapsed {
  color: var(--secondary);
  background: var(--white);
}

.faq__title button.collapsed::after,
.faq__title-2 button.collapsed::after {
  position: absolute;
  content: "+";
  width: 100%;
  height: 100%;
  left: 95%;
  top: 13px;
  font-size: 25px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .faq__title button.collapsed::after,
  .faq__title-2 button.collapsed::after {
    left: 94%;
  }
}

.faq__title button::after,
.faq__title-2 button::after {
  position: absolute;
  content: "-";
  width: 100%;
  height: 100%;
  left: 95%;
  top: 13px;
  font-size: 25px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .faq__title button::after,
  .faq__title-2 button::after {
    left: 94%;
  }
}

.faq__title-2 {
  overflow: hidden;
  margin-bottom: 15px;
}

.faq__title-2 button {
  background-color: var(--primary);
  border-radius: 3px;
}

.faq__title-2 button.collapsed {
  color: var(--secondary);
  background: var(--white-6);
}

.faq__img {
  position: relative;
  z-index: 9;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.faq__img img {
  width: 100%;
  border-radius: 5px;
}

.faq__content {
  padding-left: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq__content {
    padding-left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .faq__content {
    padding-left: 0;
    padding-top: 50px;
  }
}

.faq__content p {
  position: relative;
  z-index: 9;
}

/*----------------------------------------*/
/*  TESTIMONIAL CSS START
/*----------------------------------------*/
.testimonial__area {
  padding-top: 390px;
  background-image: url(../imgs/testimonial/1/bg-1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__area {
    padding-top: 250px;
    background-attachment: unset;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial__area {
    padding-top: 200px;
    background-attachment: unset;
  }
}

.testimonial__area-2 {
  overflow: hidden;
}

.testimonial__area-2 .line {
  padding-top: 90px;
  padding-bottom: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__area-2 .line {
    padding-top: 70px;
    padding-bottom: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial__area-2 .line {
    padding-top: 50px;
    padding-bottom: 60px;
  }
}

.testimonial__slider {
  padding-top: 10px;
  background-color: var(--secondary);
  opacity: 0.95;
}

.testimonial__slider-2 {
  text-align: center;
}

.testimonial__slider-wrapper {
  position: relative;
}

.testimonial__slide {
  text-align: center;
  padding: 50px;
}

@media only screen and (max-width: 767px) {
  .testimonial__slide {
    padding: 40px 20px;
  }
}

.testimonial__slide p {
  padding-top: 50px;
  font-weight: 400;
  font-size: 18px;
  color: var(--black-4);
  padding-bottom: 35px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .testimonial__slide p {
    font-size: 16px;
  }
}

.testimonial__slide p::after {
  position: absolute;
  content: "\f10e";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  top: -20px;
  left: calc(50% - 10px);
  color: var(--white);
  font-size: 30px;
}

.testimonial__slide h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--white);
}

.testimonial__slide h3 span {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: var(--black-4);
  padding-top: 10px;
}

.testimonial__slide-2 p {
  font-size: 18px;
  color: var(--black-2);
  padding: 70px 210px 40px 210px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial__slide-2 p {
    padding: 60px 170px 30px 170px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial__slide-2 p {
    font-size: 16px;
    padding: 60px 100px 30px 100px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial__slide-2 p {
    font-size: 16px;
    padding: 60px 0 20px;
  }
}

.testimonial__slide-2 p::after {
  position: absolute;
  content: "\f10e";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  top: 0;
  left: calc(50% - 10px);
  color: var(--primary);
  font-size: 30px;
}

.testimonial__slide-2 h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--secondary);
}

.testimonial__slide-2 h3 span {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: var(--black-2);
  padding-top: 10px;
}

.testimonial__btn {
  position: absolute;
  right: -80px;
  top: -80px;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .testimonial__btn {
    right: 0;
    top: -60px;
  }
}

.testimonial__btn .arrow-btn {
  width: 80px;
  height: 80px;
  font-size: 24px;
  color: var(--secondary);
  background: var(--white);
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (max-width: 767px) {
  .testimonial__btn .arrow-btn {
    width: 60px;
    height: 60px;
    font-size: 20px;
  }
}

.testimonial__btn .arrow-btn:hover {
  background-color: var(--primary);
  color: var(--white);
}

@media only screen and (max-width: 767px) {
  .testimonial__btn-2 {
    display: none;
  }
}

.testimonial__btn-2 .swiper-btn-prev {
  position: absolute;
  left: 30px;
  top: calc(50% - 50px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 1;
}

.testimonial__btn-2 .swiper-btn-prev:hover::after {
  border: 1px solid var(--secondary);
}

.testimonial__btn-2 .swiper-btn-prev:hover img {
  -webkit-filter: brightness(0);
  filter: brightness(0);
}

.testimonial__btn-2 .swiper-btn-prev::after {
  position: absolute;
  content: "";
  width: 60px;
  height: 60px;
  border: 1px solid var(--white-2);
  right: -23px;
  top: 14px;
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.testimonial__btn-2 .swiper-btn-prev img {
  -webkit-filter: brightness(8);
  filter: brightness(8);
}

.testimonial__btn-2 .swiper-btn-next {
  position: absolute;
  right: 31px;
  top: calc(50% - 50px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 1;
}

.testimonial__btn-2 .swiper-btn-next:hover::after {
  border: 1px solid var(--secondary);
}

.testimonial__btn-2 .swiper-btn-next:hover img {
  -webkit-filter: brightness(0);
  filter: brightness(0);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.testimonial__btn-2 .swiper-btn-next::after {
  position: absolute;
  content: "";
  width: 60px;
  height: 60px;
  border: 1px solid var(--white-2);
  left: 38px;
  top: 14px;
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.testimonial__btn-2 .swiper-btn-next img {
  -webkit-filter: brightness(8);
  filter: brightness(8);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

/*----------------------------------------*/
/* 22. TEAM CSS START
/*----------------------------------------*/
.team__area {
  position: relative;
  padding-bottom: 260px;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .team__area {
    padding-bottom: 320px;
  }
}

.team__area::after {
  position: absolute;
  content: "";
  width: 50%;
  height: 100%;
  background: var(--white-6);
  top: 0;
  left: 0;
  z-index: -1;
}

.team__area .line {
  padding-top: 128px;
  padding-bottom: 110px;
}

@media only screen and (max-width: 767px) {
  .team__area .line {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.team__area-2 {
  overflow: hidden;
}

.team__area-2 .line {
  padding-bottom: 0;
}

.team__area-4 {
  overflow: hidden;
}

.team__area-4 .line {
  padding-top: 130px;
  padding-bottom: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__area-4 .line {
    padding-top: 90px;
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .team__area-4 .line {
    padding-top: 60px;
    padding-bottom: 10px;
  }
}

.team__title-wrapper {
  text-align: center;
  padding-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .team__title-wrapper {
    padding-bottom: 50px;
  }
}

.team__title-wrapper-2 {
  padding-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .team__title-wrapper-2 {
    padding-bottom: 50px;
  }
}

.team__member,
.team__member-4 {
  overflow: hidden;
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .team__member,
  .team__member-4 {
    padding-bottom: 10px;
  }
}

.team__member:hover img,
.team__member-4:hover img {
  -webkit-transform: scale(1.07);
  transform: scale(1.07);
}

.team__member:hover .team__social,
.team__member-4:hover .team__social {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.team__member:hover .team__info a,
.team__member-4:hover .team__info a {
  color: var(--primary);
}

.team__member img,
.team__member-4 img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__member img,
  .team__member-4 img {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__member img,
  .team__member-4 img {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .team__member img,
  .team__member-4 img {
    width: 100%;
  }
}

.team__member-4 {
  padding-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .team__member-4 {
    padding-bottom: 30px;
  }
}

.team__member-img {
  position: relative;
}

.team__member-name {
  font-weight: 500;
  font-size: 36px;
  line-height: 1.2;
  color: var(--secondary);
  padding-bottom: 10px;
}

.team__member-designation {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--black-2);
  padding-bottom: 25px;
}

.team__member-info {
  max-width: 330px;
  padding-top: 30px;
}

.team__member-info li {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 0.1fr 30px 1.5fr;
  grid-template-columns: 1fr 0.1fr 1.5fr;
  grid-gap: 30px;
  color: var(--black-2);
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .team__member-info li {
    grid-gap: 20px;
  }
}

.team__member-info li strong {
  color: var(--secondary);
  font-weight: 400;
}

.team__member-skills {
  background: var(--white);
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .team__member-skills {
    padding: 30px 20px;
  }
}

.team__member-works {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  padding-top: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__member-works {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 767px) {
  .team__member-works {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.team__member-work {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.team__member-work::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__member-work::after {
    opacity: 1;
    visibility: visible;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__member-work::after {
    opacity: 1;
    visibility: visible;
  }
}

@media only screen and (max-width: 767px) {
  .team__member-work::after {
    opacity: 1;
    visibility: visible;
  }
}

.team__member-work:hover::after {
  opacity: 1;
  visibility: visible;
}

.team__member-work:hover .work-title {
  font-size: 18px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.team__member-work img {
  width: 100%;
}

.team__member-work-wrapper {
  background: var(--white);
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .team__member-work-wrapper {
    padding: 30px 20px;
  }
}

.team__member-work .work-title {
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, 180%);
  transform: translate(-50%, 180%);
  color: var(--white);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 1;
  padding: 20px;
  text-transform: capitalize;
}

.team__member-work .work-title:hover {
  color: var(--primary);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__member-work .work-title {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__member-work .work-title {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .team__member-work .work-title {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0;
  }
}

.team__img {
  position: relative;
  overflow: hidden;
}

.team__img img {
  height: 350px;
  width: 270px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__img img {
    height: 400px;
    width: 235px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__img img {
    height: 400px;
    width: 235px;
  }
}

@media only screen and (max-width: 767px) {
  .team__img img {
    height: 400px;
    width: 100%;
  }
}

.team__img-2 {
  position: relative;
  overflow: hidden;
}

.team__img-2 img {
  width: 100%;
  border-radius: 5px;
}

.team__img-4 {
  position: relative;
  overflow: hidden;
}

.team__social {
  padding: 8px 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--primary);
  -webkit-transform: translateX(101%);
  transform: translateX(101%);
  -webkit-transition: all 1s;
  transition: all 1s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__social {
    border-top-left-radius: 5px;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__social {
    padding: 3px 10px;
    border-top-left-radius: 5px;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@media only screen and (max-width: 767px) {
  .team__social {
    border-top-left-radius: 5px;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.team__social li {
  display: inline;
}

.team__social li a {
  display: inline-block;
  color: var(--white);
  padding: 3px 6px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.team__social li a:hover {
  color: var(--secondary);
}

.team__social-2 {
  padding: 15px 30px 12px;
  position: absolute;
  bottom: 0;
  right: calc(50% - 133px);
  background-color: var(--primary);
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 5px;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__social-2 {
    right: calc(50% - 120px);
    padding: 10px 20px 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__social-2 {
    width: 100%;
    right: 0;
    padding: 12px 8px;
  }
}

.team__social-2 li {
  display: inline;
  color: var(--white);
}

.team__social-2 li a {
  font-size: 18px;
  display: inline-block;
  color: var(--white);
  padding: 3px 6px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .team__social-2 li a {
    font-size: 16px;
  }
}

.team__social-2 li a:hover {
  color: var(--secondary);
}

.team__info {
  text-align: center;
  padding: 20px 0;
}

.team__info a {
  display: inline-block;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--secondary);
  margin-bottom: 3px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.team__info p {
  font-size: 14px;
}

.team__info-2 {
  text-align: left;
  position: relative;
  padding: 15px 0 25px;
}

.team__count {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--gray-3);
  position: absolute;
  right: 0;
}

.team__detail {
  background-color: var(--white-6);
  margin-top: -190px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .team__detail {
    margin-top: -100px;
  }
}

@media only screen and (max-width: 767px) {
  .team__detail {
    margin-top: 0;
    overflow: hidden;
  }
}

.team__detail-left {
  padding: 30px 30px 60px;
  background: var(--white);
  border-radius: 5px;
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .team__detail-left {
    padding: 30px 20px 60px;
  }
}

.team__detail-left img {
  width: 100%;
  border-radius: 5px;
}

.team__detail-right {
  padding-left: 5px;
}

.team__detail-content {
  background: var(--white);
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .team__detail-content {
    padding: 30px 20px;
  }
}

.team__detail-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--secondary);
  padding-bottom: 15px;
}

.team__v2 .team__member,
.team__v2 .team__member-4 {
  padding-bottom: 25px;
}

/*----------------------------------------*/
/* 23. PORTFLOLIO CSS START
/*----------------------------------------*/
.portfolio__area {
  overflow: hidden;
  margin-top: -375px;
}

.portfolio__row {
  margin-right: -500px;
}

.portfolio__title-wrapper {
  display: inline-block;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  z-index: 9;
  position: relative;
  margin-top: -151px;
}

@media only screen and (max-width: 767px) {
  .portfolio__title-wrapper {
    margin-top: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    margin-bottom: 30px;
  }
}

.portfolio__slider .swiper {
  width: 100%;
  height: 100%;
}

.portfolio__slider .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px;
}

.portfolio__slider .swiper-slide {
  width: 70%;
}

.portfolio__slider-thumbs {
  height: 100%;
}

.portfolio__slider-thumbs .swiper-slide-shadow-left,
.portfolio__slider-thumbs .swiper-slide-shadow-right {
  background-image: none;
}

.portfolio__slide-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 100%;
}

.portfolio__slide-info h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: var(--secondary);
  padding-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .portfolio__slide-info h4 {
    font-size: 16px;
    line-height: 20px;
  }
}

.portfolio__slide-info p {
  font-size: 15px;
}

.portfolio__slide-info p span {
  color: var(--secondary);
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .portfolio__slide-info p span {
    display: block;
  }
}

.portfolio__slide a {
  width: 100%;
}

.portfolio__v3 .line {
  padding-bottom: 0;
}

.cursor {
  position: fixed;
  pointer-events: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: var(--white);
  background: var(--secondary);
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  z-index: 999;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.cursor.video {
  width: 200px;
  height: 200px;
}

@media only screen and (max-width: 767px) {
  .cursor.video {
    display: none;
  }
}

.cursor1 {
  position: fixed;
  width: 40px;
  height: 40px;
  border: 1px solid var(--primary);
  border-radius: 50%;
  left: 0;
  top: 0;
  pointer-events: none;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.15s;
  transition: 0.15s;
  z-index: 999;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cursor1 {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cursor1 {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .cursor1 {
    display: none;
  }
}

.cursor2 {
  position: fixed;
  width: 8px;
  height: 8px;
  background-color: var(--primary);
  border-radius: 50%;
  left: 0;
  top: 0;
  pointer-events: none;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.2s;
  transition: 0.2s;
  z-index: 999;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cursor2 {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cursor2 {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .cursor2 {
    display: none;
  }
}

/*----------------------------------------*/
/* 24. COMPANY CSS START
/*----------------------------------------*/
.company__area {
  overflow: hidden;
}

.company__area .line {
  padding-top: 10px;
  padding-bottom: 130px;
}

@media only screen and (max-width: 767px) {
  .company__area .line {
    padding-top: 0;
    padding-bottom: 60px;
  }
}

.company__area-2 {
  background-color: var(--primary);
  padding: 30px 0;
}

.company__area-2 .marquee {
  --gap: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  gap: var(--gap);
}

.company__area-2 .marquee li {
  font-size: 28px;
  font-weight: 500;
  color: var(--secondary);
  text-transform: uppercase;
}

.company__area-2 .marquee__content {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  min-width: 100%;
  gap: var(--gap);
  -webkit-animation: scroll 10s linear infinite;
  animation: scroll 10s linear infinite;
}

.company__area-3 .line {
  padding-top: 130px;
}

@media only screen and (max-width: 767px) {
  .company__area-3 .line {
    padding-top: 60px;
  }
}

.company__logos {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr 30px 1fr 30px 1fr 30px 1fr;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 60px 30px;
}

@media only screen and (max-width: 767px) {
  .company__logos {
    -ms-grid-columns: 1fr 20px 1fr 20px 1fr;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px 20px;
  }
}

.company__logo {
  text-align: center;
  position: relative;
  z-index: 9;
}

.company__logo:nth-child(1),
.company__logo:nth-child(6) {
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .company__logo:nth-child(1),
  .company__logo:nth-child(6) {
    text-align: unset;
  }
}

.company__logo:nth-child(5),
.company__logo:nth-child(10) {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .company__logo:nth-child(5),
  .company__logo:nth-child(10) {
    text-align: unset;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .company__logo img {
    width: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .company__logo img {
    width: 72px;
  }
}

/*----------------------------------------*/
/* 25. FEATURE CSS START
/*----------------------------------------*/
.feature__area {
  background-image: url(../imgs/feature/2/bg.png);
  overflow: hidden;
}

.feature__area .line {
  padding-bottom: 30px;
}

.feature__area-2 {
  overflow: hidden;
}

.feature__area-3 {
  overflow: hidden;
}

.feature__area-3 .line {
  padding-bottom: 0;
}

.feature__left {
  position: relative;
  z-index: 1;
  margin-top: 5rem;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__left {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .feature__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.feature__left img {
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  object-fit: cover;
}

img {
  object-fit: cover !important;
}

.feature__content-3 {
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .feature__content-3 {
    padding-bottom: 40px;
  }
}

.feature__content-3 p {
  position: relative;
  padding-right: 40px;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__content-3 p {
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .feature__content-3 p {
    padding-right: 0;
  }
}

.feature__right {
  padding-left: 100px;
  padding-top: 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature__right {
    padding-left: 50px;
    padding-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__right {
    padding-left: 10px;
    padding-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .feature__right {
    padding-left: 0;
  }
}

.feature__title-3 {
  font-weight: 500;
  font-size: 22px;
  line-height: 1;
  color: var(--secondary);
  padding-bottom: 20px;
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .feature__title-3 {
    padding-bottom: 15px;
  }
}

.feature__title-wrapper-2 {
  padding-bottom: 25px;
}

.feature__sticker {
  position: absolute;
  width: 200px;
  height: 200px;
  background: var(--white);
  left: 40%;
  top: 20%;
  border-radius: 100%;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__sticker {
    width: 160px;
    height: 160px;
    left: 30%;
    top: 30%;
  }
}

@media only screen and (max-width: 767px) {
  .feature__sticker {
    width: 160px;
    height: 160px;
    left: 30%;
  }
}

.feature__sticker::after {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  background: var(--primary);
  left: 25%;
  top: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__sticker::after {
    left: 17%;
    top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .feature__sticker::after {
    left: 17%;
    top: 30px;
  }
}

.feature__img-1 {
  height: 230px;
  width: 310px;
  border-radius: 5px;
  padding-right: 50px;
  -webkit-transform: translateY(-70px);
  transform: translateY(-70px);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature__img-1 {
    padding-right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__img-1 {
    padding-right: 2%;
    width: 58%;
  }
}

@media only screen and (max-width: 767px) {
  .feature__img-1 {
    padding-right: 2%;
    width: 58%;
  }
}

.feature__img-2 {
  height: 230px;
  width: 200px;
  -webkit-transform: translateY(-60px);
  transform: translateY(-60px);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__img-2 {
    width: 40%;
  }
}

@media only screen and (max-width: 767px) {
  .feature__img-2 {
    width: 40%;
  }
}

.feature__img-3 {
  height: 270px;
  width: 600px;
  margin-top: 3rem;
  -webkit-transform: translateY(-90px);
  transform: translateY(-90px);
  padding-left: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature__img-3 {
    width: 100%;
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__img-3 {
    width: 100%;
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .feature__img-3 {
    width: 100%;
    padding-left: 0;
  }
}

.feature__img-4 {
  position: absolute;
  top: calc(50% - 21px);
  left: calc(50% - 22px);
}

.feature__img-5 {
  position: absolute;
  top: calc(50% - 62px);
  left: calc(50% - 62px);
  -webkit-animation: rotate 10s infinite linear;
  animation: rotate 10s infinite linear;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature__img-5 {
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .feature__img-5 {
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    width: 100px;
  }
}

.feature__tab-wrapper {
  position: relative;
}

.feature__tab-wrapper button {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: var(--black-2);
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--white-2);
  padding: 0 30px;
  position: relative;
  z-index: 9;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.feature__tab-wrapper button.active,
.feature__tab-wrapper button:hover {
  color: var(--secondary);
  border-bottom: 1px solid var(--black-4);
}

.feature__tab-wrapper #feature_tab {
  border: none;
  padding-bottom: 20px;
}

.feature__tab-wrapper #feature_tab li:first-child button {
  padding-left: 0;
}

.feature__list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 10px 1fr;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  padding-top: 25px;
}

@media only screen and (max-width: 767px) {
  .feature__list {
    -ms-grid-columns: 1fr;
    grid-template-columns: repeat(1, 1fr);
  }
}

.feature__list li {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--secondary);
  position: relative;
  z-index: 9;
}

/*----------------------------------------*/
/*  26. PORTFOLIO CSS START
/*----------------------------------------*/
.progress__area {
  overflow: hidden;
}

.progress__area .line {
  padding-bottom: 0;
}

.progress__left {
  position: relative;
  z-index: 9;
}

.progress__left p {
  max-width: 470px;
}

.progress__right {
  padding-left: 80px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .progress__right {
    padding-left: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .progress__right {
    padding-left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .progress__right {
    padding-left: 0;
    padding-top: 30px;
  }
}

.progress__right .progress {
  height: 1.5px;
  background: var(--white-2);
}

.progress__right .progress-bar {
  background-color: var(--primary);
}

.progress__title {
  font-weight: 500;
  font-size: 36px;
  line-height: 1.2;
  color: var(--secondary);
  padding-bottom: 20px;
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .progress__title {
    font-size: 32px;
  }
}

.progress__label {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--secondary);
}

/*----------------------------------------*/
/*  27. WORKFLOW CSS START
/*----------------------------------------*/
.workflow__area .line {
  padding-bottom: 0;
}

.workflow__wrapper {
  background-image: url(../imgs/bg/workflow-bg.png);
  background-position: 160px 0;
  background-repeat: no-repeat;
  padding: 100px 0 200px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .workflow__wrapper {
    background-size: 70% 100%;
    padding: 0 0 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .workflow__wrapper {
    background-size: 70% 100%;
    padding: 30px 0;
  }
}

@media only screen and (max-width: 767px) {
  .workflow__wrapper {
    padding: 0;
  }
}

.workflow__left {
  position: relative;
  z-index: 1;
}

.workflow__left img {
  border-radius: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .workflow__left img {
    width: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .workflow__left img {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .workflow__left img {
    width: 100%;
  }
}

.workflow__right {
  padding-left: 100px;
  padding-top: 100px;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .workflow__right {
    padding-left: 60px;
    padding-top: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .workflow__right {
    padding-left: 30px;
    padding-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .workflow__right {
    padding-left: 0;
    padding-top: 220px;
  }
}

.workflow__right p {
  padding: 30px 0;
}

.workflow__right ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
}

.workflow__right ul li a {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--black-2);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
}

.workflow__right ul li a::after {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  left: 0;
  bottom: 0px;
  background-color: var(--secondary);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.workflow__right ul li a:hover {
  color: var(--secondary);
}

.workflow__right ul li a:hover::after {
  width: 100%;
}

.workflow__thumb {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: translateY(-15%);
  transform: translateY(-15%);
}

.workflow__thumb-2 {
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: translateY(80%);
  transform: translateY(80%);
}

/*----------------------------------------*/
/*  28. STEP CSS START
/*----------------------------------------*/
.step__area {
  overflow: hidden;
  padding: 50px 0;
}

.step__content {
  text-align: center;
  background-image: url(../imgs/bg/step-1-bg.png);
  background-repeat: no-repeat;
  background-position: top center;
  padding-top: 30px;
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .step__content {
    padding-bottom: 20px;
  }
}

.step__content.step-2 {
  background-image: url(../imgs/bg/step-2-bg.png);
}

.step__content.step-3 {
  background-image: url(../imgs/bg/step-3-bg.png);
}

.step__content.step-4 {
  background-image: url(../imgs/bg/step-4-bg.png);
}

.step__content img {
  margin-bottom: 20px;
}

.step__content h4 {
  font-weight: 500;
  font-size: 22px;
  line-height: 1.2;
  color: var(--secondary);
  padding-bottom: 15px;
}

/*----------------------------------------*/
/*  29. GALLERY CSS START
/*----------------------------------------*/
.gallery__area {
  overflow: hidden;
}

.gallery__area .line {
  padding: 10px 0 30px;
}

.gallery__top {
  text-align: center;
}

.gallery__top p {
  font-size: 14px;
  position: relative;
  z-index: 9;
}

.gallery__top p a {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--secondary);
  padding: 5px 14px;
  border: 1px solid var(--white-4);
  border-radius: 30px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-left: 8px;
}

.gallery__top p a:hover {
  color: var(--primary);
  border-color: var(--primary);
}

.gallery__items {
  padding-left: 100px;
  display: -ms-grid;
  display: grid;
  /* -ms-grid-columns: (1fr)[5]; */
  /* grid-template-columns: repeat(5, 1fr); */
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .gallery__items {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .gallery__items {
    padding-left: 0;
    /* -ms-grid-columns: (1fr)[3]; */
    /* grid-template-columns: repeat(3, 1fr); */
  }
}

@media only screen and (max-width: 767px) {
  .gallery__items {
    padding-left: 0;
    /* -ms-grid-columns: (1fr)[2]; */
    /* grid-template-columns: repeat(2, 1fr); */
  }
}

.gallery__item img {
  width: 100%;
}

/*----------------------------------------*/
/* 30. BLOG CSS START
/*----------------------------------------*/
.blog__area {
  background-color: var(--white-6);
  overflow: hidden;
}

.blog__area .line {
  padding-bottom: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area .line {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__area .line {
    padding-bottom: 60px;
  }
}

.blog__area-2 {
  overflow: hidden;
}

.blog__area-2 .line {
  padding-top: 130px;
  padding-bottom: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__area-2 .line {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__area-2 .line {
    padding-top: 60px;
    padding-bottom: 0px;
  }
}

.blog__top {
  padding-bottom: 60px;
}

.blog__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .blog__btn {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-top: 20px;
  }
}

.blog__btn a {
  padding-right: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--secondary);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  z-index: 9;
}

.blog__btn a i {
  padding-left: 5px;
}

.blog__btn a:hover {
  color: var(--primary);
}

.blog__item-wrapper {
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__item-wrapper {
    padding-bottom: 30px;
  }
}

.blog__item-wrapper article {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1.5fr 2fr;
  grid-template-columns: 1.5fr 2fr;
  background-color: var(--white);
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__item-wrapper article {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 767px) {
  .blog__item-wrapper article {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.blog__item-wrapper article:hover img {
  -webkit-transform: scale(1.1) translateX(4%);
  transform: scale(1.1) translateX(4%);
}

.blog__item-wrapper-2 {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .blog__item-wrapper-2 article {
    padding-bottom: 50px;
  }
}

.blog__item-wrapper-2 article:hover img {
  -webkit-transform: scale(1.1) translateX(4%);
  transform: scale(1.1) translateX(4%);
}

.blog__content {
  padding: 52px 35px 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__content {
    padding: 30px 20px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__content {
    padding: 30px 10px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__content {
    padding: 30px 10px 40px;
  }
}

.blog__content-2 {
  padding-top: 20px;
}

.blog__content-2 p {
  padding-bottom: 22px;
}

.blog__item {
  padding-bottom: 55px;
  position: relative;
  z-index: 9;
}

.blog__item img {
  width: 80%;
  border-radius: 5px;
}

.blog__list {
  padding-right: 75px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__list {
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__list {
    padding-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__list {
    padding-right: 0;
  }
  .blog__item img {
    width: 100% !important;
  }
}

.blog__img {
  overflow: hidden;
  border-radius: 5px;
}

.blog__img img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.33, 0.02, 0, 0.93);
  transition: -webkit-transform 1s cubic-bezier(0.33, 0.02, 0, 0.93);
  transition: transform 1s cubic-bezier(0.33, 0.02, 0, 0.93);
  transition: transform 1s cubic-bezier(0.33, 0.02, 0, 0.93),
    -webkit-transform 1s cubic-bezier(0.33, 0.02, 0, 0.93);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__img img {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .blog__img img {
    width: 100%;
  }
}

.blog__meta,
.blog__meta-4 {
  font-weight: 400;
  font-size: 13px;
  line-height: 23px;
  color: var(--black-2);
  padding-bottom: 5px;
}

.blog__meta strong,
.blog__meta-4 strong {
  color: var(--secondary);
  font-weight: 400;
}

.blog__meta a,
.blog__meta-4 a {
  color: var(--black-2);
}

.blog__meta a:hover,
.blog__meta-4 a:hover {
  color: var(--primary);
}

.blog__meta-4 {
  font-size: 16px;
  padding-top: 30px;
}

.blog__btn-rm {
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--secondary);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-top: 35px;
}

@media only screen and (max-width: 767px) {
  .blog__btn-rm {
    margin-top: 20px;
  }
}

.blog__btn-rm:hover {
  color: var(--primary);
}

.blog__btn-rm:hover i {
  color: var(--primary);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.blog__btn-rm i {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.blog__title,
.blog__title-2 {
  display: block;
  font-weight: 500;
  font-size: 26px;
  line-height: 1.2;
  color: var(--secondary);
  padding-bottom: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__title,
  .blog__title-2 {
    font-size: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__title,
  .blog__title-2 {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog__title,
  .blog__title-2 {
    padding-bottom: 20px;
  }
}

.blog__title:hover,
.blog__title-2:hover {
  color: var(--primary);
}

.blog__title-2 {
  padding-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__title-2 {
    font-size: 22px;
  }
}

.blog__title-4 {
  display: block;
  font-weight: 500;
  font-size: 45px;
  line-height: 1.1;
  color: var(--secondary);
  padding-bottom: 22px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__title-4 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__title-4 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__title-4 {
    font-size: 24px;
  }
}

.blog__title-4:hover {
  color: var(--primary);
}

.blog__detail {
  overflow: hidden;
}

.blog__detail-title {
  font-weight: 600;
  font-size: 80px;
  line-height: 1.1;
  color: var(--secondary);
  padding-bottom: 45px;
  border-bottom: 1px solid var(--white-6);
  border-radius: 3px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__detail-title {
    font-size: 48px;
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__detail-title {
    font-size: 40px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__detail-title {
    font-size: 36px;
    padding-bottom: 20px;
  }
}

.blog__detail-meta {
  padding: 18px 0;
  position: relative;
  z-index: 9;
}

.blog__detail-meta ul {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__detail-meta ul {
    -ms-grid-columns: 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr;
    grid-template-columns: repeat(5, 1fr);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__detail-meta ul {
    -ms-grid-columns: 1fr 15px 1fr 15px 1fr 15px 1fr 15px 1fr;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__detail-meta ul {
    -ms-grid-columns: 1fr 30px 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
}

.blog__detail-meta ul li {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  color: var(--secondary);
}

.blog__detail-meta ul li a {
  color: var(--secondary);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.blog__detail-meta ul li a:hover {
  color: var(--primary);
}

.blog__detail-meta ul li span {
  display: block;
  font-weight: 400;
  font-size: 14px;
  color: var(--black-2);
}

.blog__detail-thumb {
  width: 100%;
  margin: 50px 0 42px;
  border-top: 1px solid var(--white-6);
  border-radius: 5px;
  position: relative;
  z-index: 9;
}

.blog__detail-content {
  padding-right: 75px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__detail-content {
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__detail-content {
    padding-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__detail-content {
    padding-right: 0;
  }
}

.blog__detail-content h1,
.blog__detail-content h2 {
  font-size: 32px;
  padding-bottom: 20px;
  padding-top: 10px;
  font-weight: 600;
}

.blog__detail-content h3 {
  font-size: 28px;
  padding-bottom: 10px;
  padding-top: 10px;
  font-weight: 600;
}

.blog__detail-content h4 {
  font-size: 24px;
  padding-bottom: 10px;
  padding-top: 10px;
  font-weight: 600;
}

.blog__detail-content h5 {
  font-size: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.blog__detail-content h6 {
  font-size: 18px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.blog__detail-content p {
  padding-bottom: 20px;
  position: relative;
  z-index: 9;
}

.blog__detail-content img {
  border-radius: 5px;
  width: 100%;
}

.blog__detail-content ul,
.blog__detail-content ol {
  padding-bottom: 20px;
}

.blog__detail-content ul li,
.blog__detail-content ol li {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: var(--black-2);
  padding-left: 24px;
  position: relative;
  z-index: 9;
}

.blog__detail-content ul li::before,
.blog__detail-content ol li::before {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  left: 10px;
  top: 15px;
  background-color: var(--black-2);
  border-radius: 100%;
}

.blog__detail-content .box-2-col {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  padding-bottom: 45px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__detail-content .box-2-col {
    -ms-grid-columns: 1fr;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .blog__detail-content .box-2-col {
    -ms-grid-columns: 1fr;
    grid-template-columns: repeat(1, 1fr);
  }
}

.blog__detail-content .quote {
  background-color: var(--gray-6);
  padding: 30px 60px 20px;
  margin: 20px 0 28px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__detail-content .quote {
    padding: 25px 25px 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__detail-content .quote {
    padding: 25px 25px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__detail-content .quote {
    padding: 25px 25px 10px;
  }
}

.blog__detail-content .quote::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: var(--primary);
}

.blog__detail-content .quote p {
  font-weight: 400;
  font-size: 30px;
  line-height: 1.5;
  color: var(--black-2);
  font-style: italic;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__detail-content .quote p {
    font-size: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__detail-content .quote p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__detail-content .quote p {
    font-size: 18px;
  }
}

.blog__detail-content img {
  position: relative;
  z-index: 9;
}

.blog__detail-btm {
  padding-top: 20px;
  border-top: 1px solid var(--white-7);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 50px;
  margin-right: 75px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__detail-btm {
    gap: 30px;
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog__detail-btm {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 30px;
    margin-right: 0;
  }
}

.blog__detail-tags {
  position: relative;
  z-index: 9;
}

.blog__detail-tags li {
  color: var(--secondary);
  display: inline-block;
  padding-right: 10px;
  font-size: 18px;
}

.blog__detail-tags li a {
  display: block;
  padding: 3px 8px;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 22px;
  color: var(--black-2);
  border: 1px solid var(--white-7);
}

.blog__detail-tags li a:hover {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.blog__detail-share {
  position: relative;
  z-index: 9;
}

.blog__detail-share li {
  color: var(--secondary);
  display: inline-block;
  padding-right: 10px;
  font-size: 18px;
  font-weight: 400;
}

.blog__detail-share li a {
  display: block;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: var(--black-2);
}

.blog__detail-share li a:hover {
  color: var(--primary);
}

@media only screen and (max-width: 767px) {
  .blog__detail .widget__thumb {
    margin-bottom: 0;
  }
}

.blog__pagination {
  padding-top: 70px;
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .blog__pagination {
    padding-top: 30px;
    padding-bottom: 50px;
  }
}

.blog__pagination ul li {
  display: inline-block;
}

.blog__pagination ul li a {
  width: 50px;
  height: 50px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  color: var(--black-2);
  border: 1px solid var(--white-2);
  margin-right: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.blog__pagination ul li a:hover,
.blog__pagination ul li a.active {
  background-color: var(--primary);
  color: var(--white);
  border-color: var(--primary);
}

.blog__v2 .blog__item-wrapper {
  padding-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__v2 .blog__item-wrapper {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__v2 .blog__item-wrapper {
    padding-bottom: 20px;
  }
}

.blog__v3 .blog__item-wrapper-2 {
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog__v3 .blog__item-wrapper-2 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .blog__v3 .blog__item-wrapper-2 {
    padding-bottom: 20px;
  }
}

.comment__area {
  padding-top: 130px;
  padding-right: 75px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .comment__area {
    padding-top: 90px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .comment__area {
    padding-top: 70px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .comment__area {
    padding-right: 0;
    padding-top: 60px;
  }
}

.comment__total {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.3;
  color: var(--secondary);
  padding-bottom: 40px;
  position: relative;
  z-index: 9;
}

.comment__total span {
  color: var(--gray-7);
}

.comment__item {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 70px 20px auto;
  grid-template-columns: 70px auto;
  grid-gap: 20px;
  padding-bottom: 45px;
  position: relative;
  z-index: 9;
}

.comment__author {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;
  color: var(--secondary);
  padding-top: 10px;
  padding-bottom: 5px;
}

.comment__date {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: var(--black-2);
  padding-bottom: 20px;
}

.comment__reply {
  padding-left: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .comment__reply {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .comment__reply {
    padding-left: 30px;
  }
}

.comment__reply-btn {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  color: var(--secondary);
  margin-top: 15px;
  text-transform: capitalize;
}

.comment__reply-btn:hover {
  color: var(--primary);
}

.comment__form {
  position: relative;
  z-index: 9;
}

.comment__form-wrapper {
  padding-top: 70px;
  padding-right: 75px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .comment__form-wrapper {
    padding-right: 30px;
    padding-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .comment__form-wrapper {
    padding-top: 30px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .comment__form-wrapper {
    padding-right: 0;
    padding-top: 50px;
    padding-bottom: 60px;
  }
}

.comment__form-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.1;
  color: var(--secondary);
  text-transform: capitalize;
  padding-bottom: 30px;
  position: relative;
  z-index: 9;
}

/*----------------------------------------*/
/*  31. CONTACT CSS START
/*----------------------------------------*/
.contact__area {
  position: relative;
  overflow: hidden;
}

.contact__area .line {
  padding: 0;
}

.contact__area-2 {
  background-color: var(--white-6);
  text-align: center;
  overflow: hidden;
}

.contact__map {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.contact__map iframe {
  width: 1000px;
  margin-left: -700px;
}

@media only screen and (max-width: 767px) {
  .contact__map iframe {
    width: 100%;
    height: 400px;
    margin-left: 0;
  }
}

.contact__content {
  position: relative;
  z-index: 9;
  padding: 120px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact__content {
    padding: 90px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__content {
    padding: 90px 0;
  }
}

@media only screen and (max-width: 767px) {
  .contact__content {
    padding: 60px 15px;
  }
}

.contact__content p {
  padding-bottom: 50px;
  opacity: 0;
  -webkit-animation: wcfadeUp 1s 0.6s forwards;
  animation: wcfadeUp 1s 0.6s forwards;
}

.contact__content-2 h2 {
  font-weight: 500;
  font-size: 50px;
  line-height: 1.1;
  color: var(--secondary);
  padding-bottom: 20px;
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .contact__content-2 h2 {
    font-size: 30px;
  }
}

.contact__content-2 p {
  max-width: 540px;
  margin: 0 auto;
  padding-bottom: 30px;
  position: relative;
  z-index: 9;
}

.contact__content-2 a {
  border-radius: 5px;
}

.contact__title {
  font-weight: 500;
  font-size: 36px;
  line-height: 1.2;
  color: var(--secondary);
  padding-bottom: 10px;
  opacity: 0;
  -webkit-animation: wcfadeUp 1s 0.45s forwards;
  animation: wcfadeUp 1s 0.45s forwards;
}

.contact__form label,
.comment__form label {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--black);
  padding-bottom: 10px;
}

.contact__form input,
.comment__form input {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--black-2);
  background-color: var(--white-6);
  height: 60px;
  border: none;
  padding: 0 20px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.contact__form input::-webkit-input-placeholder,
.comment__form input::-webkit-input-placeholder {
  opacity: 1;
}

.contact__form input::-moz-placeholder,
.comment__form input::-moz-placeholder {
  opacity: 1;
}

.contact__form input:-ms-input-placeholder,
.comment__form input:-ms-input-placeholder {
  opacity: 1;
}

.contact__form input::-ms-input-placeholder,
.comment__form input::-ms-input-placeholder {
  opacity: 1;
}

.contact__form input::placeholder,
.comment__form input::placeholder {
  opacity: 1;
}

.contact__form input:focus,
.comment__form input:focus {
  outline: none;
}

.contact__form textarea,
.comment__form textarea {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--black-2);
  background-color: var(--white-6);
  height: 160px;
  border: none;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 30px;
  resize: none;
}

.contact__form textarea::-webkit-input-placeholder,
.comment__form textarea::-webkit-input-placeholder {
  opacity: 1;
}

.contact__form textarea::-moz-placeholder,
.comment__form textarea::-moz-placeholder {
  opacity: 1;
}

.contact__form textarea:-ms-input-placeholder,
.comment__form textarea:-ms-input-placeholder {
  opacity: 1;
}

.contact__form textarea::-ms-input-placeholder,
.comment__form textarea::-ms-input-placeholder {
  opacity: 1;
}

.contact__form textarea::placeholder,
.comment__form textarea::placeholder {
  opacity: 1;
}

.contact__form textarea:focus,
.comment__form textarea:focus {
  outline: none;
}

.contact__form button,
.comment__form button {
  border: none;
}

/*----------------------------------------*/
/*  32. 404 CSS START
/*----------------------------------------*/
.error__area {
  background-image: url(../imgs/bg/404.jpg);
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.error__area .line {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100vh;
}

.error__content {
  text-align: center;
  position: relative;
  z-index: 9;
}

.error__content img {
  margin-bottom: 20px;
  width: 100%;
}

.error__content p {
  max-width: 540px;
  margin: 0 auto;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--black-2);
  padding-bottom: 30px;
}

.error__content a {
  border-radius: 5px;
}

/*----------------------------------------*/
/* 33. FOOTER CSS START
/*----------------------------------------*/
.footer__area {
  background-color: var(--secondary);
  position: relative;
  z-index: 9;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .footer__area .g-0 {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.footer__top {
  padding: 120px 0;
  border-bottom: 1px solid var(--black-3);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__top {
    padding: 80px 0 70px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__top {
    padding: 60px 0 20px;
  }
}

.footer__top-3 {
  text-align: center;
  padding: 120px 0 120px 100px;
  border-bottom: 1px solid var(--black-3);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__top-3 {
    padding: 120px 0;
    margin-left: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__top-3 {
    padding: 90px 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer__top-3 {
    padding: 60px 0;
  }
}

.footer__top-3 p {
  font-weight: 500;
  font-size: 22px;
  line-height: 1.5;
  color: var(--white);
  padding-bottom: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__bottom-3 {
    padding: 0 10px 0 80px;
  }
}

.footer__btn {
  display: inline-block;
  font-weight: 400;
  font-size: 72px;
  line-height: 1.2;
  color: var(--white);
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .footer__btn {
    font-size: 48px;
  }
}

.footer__btn:hover {
  color: var(--primary);
}

.footer__btn:hover::after {
  background-color: var(--primary);
}

.footer__btn::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 3px;
  left: 5px;
  bottom: -5px;
  background-color: var(--white);
  border-radius: 3px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (max-width: 767px) {
  .footer__widget {
    padding-bottom: 40px;
  }
}

.footer__widget-2 {
  padding-left: 30px;
}

@media only screen and (max-width: 767px) {
  .footer__widget-2 {
    padding-left: 0;
  }
}

.footer__widget p {
  line-height: 26px;
  color: var(--black-4);
  max-width: 270px;
  padding-top: 30px;
}

.footer__widget-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: var(--white);
  text-transform: capitalize;
  padding-bottom: 20px;
}

.footer__quick-link li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--black-4);
  padding: 5px 0;
  display: inline-block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.footer__quick-link li a:hover {
  color: var(--white);
}

.footer__address li {
  color: var(--black-4);
}

.footer__address li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--black-4);
  display: inline-block;
  padding: 5px 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.footer__address li a:hover {
  color: var(--white);
}

.footer__address li a.phone {
  color: var(--white);
  position: relative;
}

.footer__address li a.phone::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0px;
  background-color: var(--white);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.footer__address li a.phone:hover::after {
  width: 0%;
}

.footer__copyright,
.footer__copyright-3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__copyright,
  .footer__copyright-3 {
    -ms-grid-column-span: 6;
    grid-column: span 6;
  }
}

.footer__copyright p,
.footer__copyright-3 p {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--white);
}

.footer__copyright p a,
.footer__copyright-3 p a {
  color: var(--primary);
}

.footer__copyright p a:hover,
.footer__copyright-3 p a:hover {
  color: var(--orange-2);
}

@media only screen and (max-width: 767px) {
  .footer__copyright-3 {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 30px;
  }
}

.footer__subscribe,
.footer__subscribe-3 {
  padding: 35px 90px 40px;
  border-left: 1px solid var(--black-3);
  border-right: 1px solid var(--black-3);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer__subscribe,
  .footer__subscribe-3 {
    padding: 35px 50px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__subscribe,
  .footer__subscribe-3 {
    -ms-grid-column-span: 6;
    grid-column: span 6;
    padding: 20px 10px 30px;
    border-right: none;
  }
}

@media only screen and (max-width: 767px) {
  .footer__subscribe,
  .footer__subscribe-3 {
    border: none;
    padding: 30px 0;
  }
}

.footer__subscribe form,
.footer__subscribe-3 form {
  position: relative;
}

.footer__subscribe input,
.footer__subscribe-3 input {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--white);
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--black-3);
  padding: 7px 30px 12px 0;
  outline: none;
}

.footer__subscribe input::-webkit-input-placeholder,
.footer__subscribe-3 input::-webkit-input-placeholder {
  opacity: 1;
  color: var(--white);
}

.footer__subscribe input::-moz-placeholder,
.footer__subscribe-3 input::-moz-placeholder {
  opacity: 1;
  color: var(--white);
}

.footer__subscribe input:-ms-input-placeholder,
.footer__subscribe-3 input:-ms-input-placeholder {
  opacity: 1;
  color: var(--white);
}

.footer__subscribe input::-ms-input-placeholder,
.footer__subscribe-3 input::-ms-input-placeholder {
  opacity: 1;
  color: var(--white);
}

.footer__subscribe input::placeholder,
.footer__subscribe-3 input::placeholder {
  opacity: 1;
  color: var(--white);
}

.footer__subscribe .submit,
.footer__subscribe-3 .submit {
  position: absolute;
  background: transparent;
  border: none;
  color: var(--white);
  font-size: 20px;
  right: 0;
  top: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.footer__subscribe .submit:hover,
.footer__subscribe-3 .submit:hover {
  color: var(--primary);
}

.footer__social,
.footer__social-3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer__social,
  .footer__social-3 {
    -ms-grid-column-span: 12;
    grid-column: span 12;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 25px 0 20px;
    border-top: 1px solid var(--black-3);
  }
}

@media only screen and (max-width: 767px) {
  .footer__social,
  .footer__social-3 {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

.footer__social-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
}

.footer__social-media li a {
  font-size: 20px;
  color: var(--white);
  display: inline-block;
  padding: 0 3px;
}

.footer__social-media li a:hover {
  color: var(--primary);
}

@media only screen and (max-width: 767px) {
  .footer__social-3 {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
}

.footer__bottom-inner {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1.125fr 2fr 0.7fr;
  grid-template-columns: 1.125fr 2fr 0.7fr;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* .footer__bottom-inner {
    -ms-grid-columns: (1fr)[12];
    grid-template-columns: repeat(12, 1fr);
  } */
}

@media only screen and (max-width: 767px) {
  .footer__bottom-inner {
    padding: 30px 0;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

/* Off canvas  */
a.meanmenu-reveal {
  display: none;
}

.mean-container .mean-bar {
  float: left;
  width: 100%;
  position: relative;
  background: #0c1923;
  padding: 4px 0;
  min-height: 42px;
  z-index: 999999;
}

.mean-container a.meanmenu-reveal {
  width: 22px;
  height: 22px;
  padding: 13px 13px 11px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  text-indent: -9999em;
  line-height: 22px;
  font-size: 1px;
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
}

.mean-container a.meanmenu-reveal span {
  display: block;
  background: #fff;
  height: 3px;
  margin-top: 3px;
}

.mean-container .mean-nav {
  float: left;
  width: 100%;
  background: #0c1923;
  margin-top: 44px;
}

.mean-container .mean-nav ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
}

.mean-container .mean-nav ul li {
  position: relative;
  float: left;
  width: 100%;
}

.mean-container .mean-nav ul li a {
  display: block;
  float: left;
  width: 90%;
  padding: 1em 5%;
  margin: 0;
  text-align: left;
  color: #fff;
  border-top: 1px solid #383838;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  text-decoration: none;
  text-transform: uppercase;
}

.mean-container .mean-nav ul li li a {
  width: 80%;
  padding: 1em 10%;
  border-top: 1px solid #f1f1f1;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  opacity: 0.75;
  filter: alpha(opacity=75);
  text-shadow: none !important;
  visibility: visible;
}

.mean-container .mean-nav ul li.mean-last a {
  border-bottom: 0;
  margin-bottom: 0;
}

.mean-container .mean-nav ul li li li a {
  width: 70%;
  padding: 1em 15%;
}

.mean-container .mean-nav ul li li li li a {
  width: 60%;
  padding: 1em 20%;
}

.mean-container .mean-nav ul li li li li li a {
  width: 50%;
  padding: 1em 25%;
}

.mean-container .mean-nav ul li a:hover {
  background: #252525;
  background: rgba(255, 255, 255, 0.1);
}

.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 1px;
  width: 26px;
  height: 32px;
  padding: 12px !important;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  font-weight: 700;
  background: rgba(255, 255, 255, 0.1);
  border: 0 !important;
  border-left: 1px solid rgba(255, 255, 255, 0.4) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  background: rgba(0, 0, 0, 0.9);
}

.mean-container .mean-push {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  clear: both;
}

.mean-nav .wrapper {
  width: 100%;
  padding: 0;
  margin: 0;
}

.mean-container .mean-bar,
.mean-container .mean-bar * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.mean-remove {
  display: none !important;
}

.li-active a {
  color: rgb(246, 138, 10) !important;
}

.text-wrap {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 75px;
  margin: 0 auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
